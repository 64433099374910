export const Greengram = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":8844,"Actual Price(INR)/Quintal":8507,"Percentage Difference":"-3.96%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":7842,"Actual Price(INR)/Quintal":8070,"Percentage Difference":"2.83%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":7854,"Actual Price(INR)/Quintal":8041,"Percentage Difference":"2.33%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":8068,"Actual Price(INR)/Quintal":8630,"Percentage Difference":"6.51%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":9042,"Actual Price(INR)/Quintal":8864,"Percentage Difference":"-2.01%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":8898,"Actual Price(INR)/Quintal":9088,"Percentage Difference":"2.09%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":8836,"Actual Price(INR)/Quintal":8808,"Percentage Difference":"-0.32%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":8871,"Actual Price(INR)/Quintal":8688,"Percentage Difference":"-2.11%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":8599,"Actual Price(INR)/Quintal":8639,"Percentage Difference":"0.46%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":8605,"Actual Price(INR)/Quintal":8407,"Percentage Difference":"-2.36%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":8482,"Actual Price(INR)/Quintal":8608,"Percentage Difference":"1.46%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":8438,"Actual Price(INR)/Quintal":8564,"Percentage Difference":"1.47%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":8525,"Actual Price(INR)/Quintal":8799,"Percentage Difference":"3.11%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":8540,"Actual Price(INR)/Quintal":8591,"Percentage Difference":"0.59%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":8288,"Actual Price(INR)/Quintal":8224,"Percentage Difference":"-0.78%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":8263,"Actual Price(INR)/Quintal":8172,"Percentage Difference":"-1.11%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":8265,"Actual Price(INR)/Quintal":8308,"Percentage Difference":"0.52%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":8127,"Actual Price(INR)/Quintal":8082,"Percentage Difference":"-0.56%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":8503,"Actual Price(INR)/Quintal":8650,"Percentage Difference":"1.70%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":8313,"Actual Price(INR)/Quintal":8063,"Percentage Difference":"-3.10%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":8054,"Actual Price(INR)/Quintal":7837,"Percentage Difference":"-2.77%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":7833,"Actual Price(INR)/Quintal":7840,"Percentage Difference":"0.09%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":7876,"Actual Price(INR)/Quintal":8051,"Percentage Difference":"2.17%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":7856,"Actual Price(INR)/Quintal":7581,"Percentage Difference":"-3.63%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":7751,"Actual Price(INR)/Quintal":7643,"Percentage Difference":"-1.41%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":7570,"Actual Price(INR)/Quintal":7450,"Percentage Difference":"-1.61%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":7480,"Actual Price(INR)/Quintal":7600,"Percentage Difference":"1.58%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":7460,"Actual Price(INR)/Quintal":7658,"Percentage Difference":"2.59%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":7589,"Actual Price(INR)/Quintal":7360,"Percentage Difference":"-3.11%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":7317,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":7305,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":7229,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":7220,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":6207,"Actual Price(INR)/Quintal":6106,"Percentage Difference":"-1.65%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":6203,"Actual Price(INR)/Quintal":6229,"Percentage Difference":"0.42%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":6227,"Actual Price(INR)/Quintal":6400,"Percentage Difference":"2.70%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":6474,"Actual Price(INR)/Quintal":6624,"Percentage Difference":"2.26%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":6378,"Actual Price(INR)/Quintal":6500,"Percentage Difference":"1.88%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":6466,"Actual Price(INR)/Quintal":6615,"Percentage Difference":"2.25%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":6484,"Actual Price(INR)/Quintal":6575,"Percentage Difference":"1.38%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":6524,"Actual Price(INR)/Quintal":6920,"Percentage Difference":"5.72%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6898,"Actual Price(INR)/Quintal":6900,"Percentage Difference":"0.03%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":6842,"Actual Price(INR)/Quintal":6662,"Percentage Difference":"-2.70%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":6683,"Actual Price(INR)/Quintal":6700,"Percentage Difference":"0.25%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":6714,"Actual Price(INR)/Quintal":6754,"Percentage Difference":"0.59%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":6717,"Actual Price(INR)/Quintal":7037,"Percentage Difference":"4.55%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":6927,"Actual Price(INR)/Quintal":7190,"Percentage Difference":"3.66%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":7378,"Actual Price(INR)/Quintal":7405,"Percentage Difference":"0.36%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":7325,"Actual Price(INR)/Quintal":7480,"Percentage Difference":"2.07%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":7337,"Actual Price(INR)/Quintal":7693,"Percentage Difference":"4.63%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":7604,"Actual Price(INR)/Quintal":7573,"Percentage Difference":"-0.41%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":7617,"Actual Price(INR)/Quintal":7664,"Percentage Difference":"0.61%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":7617,"Actual Price(INR)/Quintal":7610,"Percentage Difference":"-0.09%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":7544,"Actual Price(INR)/Quintal":7441,"Percentage Difference":"-1.38%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":7323,"Actual Price(INR)/Quintal":7325,"Percentage Difference":"0.03%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":7185,"Actual Price(INR)/Quintal":7210,"Percentage Difference":"0.35%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":7132,"Actual Price(INR)/Quintal":7010,"Percentage Difference":"-1.74%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":7147,"Actual Price(INR)/Quintal":6984,"Percentage Difference":"-2.33%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":7150,"Actual Price(INR)/Quintal":7191,"Percentage Difference":"0.57%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":7213,"Actual Price(INR)/Quintal":7325,"Percentage Difference":"1.53%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":7055,"Actual Price(INR)/Quintal":7099,"Percentage Difference":"0.62%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":7031,"Actual Price(INR)/Quintal":7095,"Percentage Difference":"0.90%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":7067,"Actual Price(INR)/Quintal":7512,"Percentage Difference":"5.92%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":7553,"Actual Price(INR)/Quintal":8020,"Percentage Difference":"5.82%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":8531,"Actual Price(INR)/Quintal":9076,"Percentage Difference":"6.00%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":9224,"Actual Price(INR)/Quintal":9530,"Percentage Difference":"3.21%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":9432,"Actual Price(INR)/Quintal":9444,"Percentage Difference":"0.13%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":9435,"Actual Price(INR)/Quintal":9663,"Percentage Difference":"2.36%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":9437,"Actual Price(INR)/Quintal":9177,"Percentage Difference":"-2.83%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":9154,"Actual Price(INR)/Quintal":9061,"Percentage Difference":"-1.03%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":9162,"Actual Price(INR)/Quintal":8907,"Percentage Difference":"-2.86%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":8828,"Actual Price(INR)/Quintal":8779,"Percentage Difference":"-0.56%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":8808,"Actual Price(INR)/Quintal":8812,"Percentage Difference":"0.05%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":8805,"Actual Price(INR)/Quintal":8667,"Percentage Difference":"-1.59%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":8691,"Actual Price(INR)/Quintal":8800,"Percentage Difference":"1.24%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":8690,"Actual Price(INR)/Quintal":8694,"Percentage Difference":"0.05%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":8657,"Actual Price(INR)/Quintal":8864,"Percentage Difference":"2.34%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":8766,"Actual Price(INR)/Quintal":8779,"Percentage Difference":"0.15%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":8710,"Actual Price(INR)/Quintal":8785,"Percentage Difference":"0.85%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":8757,"Actual Price(INR)/Quintal":8607,"Percentage Difference":"-1.74%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":8648,"Actual Price(INR)/Quintal":9078,"Percentage Difference":"4.74%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":6036,"Actual Price(INR)/Quintal":6198,"Percentage Difference":"2.61%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":6091,"Actual Price(INR)/Quintal":5966,"Percentage Difference":"-2.10%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":6118,"Actual Price(INR)/Quintal":6192,"Percentage Difference":"1.20%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":6103,"Actual Price(INR)/Quintal":6110,"Percentage Difference":"0.11%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":6055,"Actual Price(INR)/Quintal":6000,"Percentage Difference":"-0.92%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":6033,"Actual Price(INR)/Quintal":6022,"Percentage Difference":"-0.18%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":5964,"Actual Price(INR)/Quintal":6005,"Percentage Difference":"0.68%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":5918,"Actual Price(INR)/Quintal":5971,"Percentage Difference":"0.89%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6029,"Actual Price(INR)/Quintal":6151,"Percentage Difference":"1.98%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":6044,"Actual Price(INR)/Quintal":6040,"Percentage Difference":"-0.07%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":6307,"Actual Price(INR)/Quintal":6443,"Percentage Difference":"2.11%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":6393,"Actual Price(INR)/Quintal":6456,"Percentage Difference":"0.98%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":6388,"Actual Price(INR)/Quintal":6366,"Percentage Difference":"-0.35%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":6367,"Actual Price(INR)/Quintal":6264,"Percentage Difference":"-1.64%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":6301,"Actual Price(INR)/Quintal":6296,"Percentage Difference":"-0.08%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":6280,"Actual Price(INR)/Quintal":6310,"Percentage Difference":"0.48%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":6336,"Actual Price(INR)/Quintal":6446,"Percentage Difference":"1.71%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":6204,"Actual Price(INR)/Quintal":6340,"Percentage Difference":"2.15%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":6291,"Actual Price(INR)/Quintal":6182,"Percentage Difference":"-1.76%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":6232,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"-0.52%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":6166,"Actual Price(INR)/Quintal":6012,"Percentage Difference":"-2.56%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":6195,"Actual Price(INR)/Quintal":6049,"Percentage Difference":"-2.41%","YEAR":2022},
{ "Year : Month Week#":"Junwk","Predicted Price(INR)/Quintal":6210,"Actual Price(INR)/Quintal":6250,"Percentage Difference":"0.64%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":6091,"Actual Price(INR)/Quintal":6034,"Percentage Difference":"-0.94%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":6090,"Actual Price(INR)/Quintal":6114,"Percentage Difference":"0.39%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":6141,"Actual Price(INR)/Quintal":6210,"Percentage Difference":"1.11%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":6105,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"1.53%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":6092,"Actual Price(INR)/Quintal":6133,"Percentage Difference":"0.67%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":6211,"Actual Price(INR)/Quintal":6461,"Percentage Difference":"3.87%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":6160,"Actual Price(INR)/Quintal":5999,"Percentage Difference":"-2.68%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":6082,"Actual Price(INR)/Quintal":5832,"Percentage Difference":"-4.29%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6069,"Actual Price(INR)/Quintal":5917,"Percentage Difference":"-2.57%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6016,"Actual Price(INR)/Quintal":6121,"Percentage Difference":"1.72%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":6093,"Actual Price(INR)/Quintal":6163,"Percentage Difference":"1.14%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":6098,"Actual Price(INR)/Quintal":6260,"Percentage Difference":"2.59%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":6232,"Actual Price(INR)/Quintal":6322,"Percentage Difference":"1.42%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":6214,"Actual Price(INR)/Quintal":6254,"Percentage Difference":"0.64%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":6249,"Actual Price(INR)/Quintal":6253,"Percentage Difference":"0.06%","YEAR":2022},
{ "Year : Month Week#":"Oct3wk","Predicted Price(INR)/Quintal":6276,"Actual Price(INR)/Quintal":6322,"Percentage Difference":"0.73%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":6104,"Actual Price(INR)/Quintal":6217,"Percentage Difference":"1.82%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":6126,"Actual Price(INR)/Quintal":6406,"Percentage Difference":"4.37%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":6330,"Actual Price(INR)/Quintal":6335,"Percentage Difference":"0.08%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":6355,"Actual Price(INR)/Quintal":6301,"Percentage Difference":"-0.86%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":6307,"Actual Price(INR)/Quintal":6342,"Percentage Difference":"0.55%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":6359,"Actual Price(INR)/Quintal":6324,"Percentage Difference":"-0.55%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6311,"Actual Price(INR)/Quintal":6197,"Percentage Difference":"-1.84%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6251,"Actual Price(INR)/Quintal":6193,"Percentage Difference":"-0.94%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6214,"Actual Price(INR)/Quintal":6210,"Percentage Difference":"-0.06%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":5723,"Actual Price(INR)/Quintal":5841,"Percentage Difference":"2.02%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":5743,"Actual Price(INR)/Quintal":5735,"Percentage Difference":"-0.14%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":5784,"Actual Price(INR)/Quintal":5803,"Percentage Difference":"0.33%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":5815,"Actual Price(INR)/Quintal":5866,"Percentage Difference":"0.87%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":5836,"Actual Price(INR)/Quintal":5793,"Percentage Difference":"-0.74%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":5822,"Actual Price(INR)/Quintal":5990,"Percentage Difference":"2.80%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":5801,"Actual Price(INR)/Quintal":5965,"Percentage Difference":"2.75%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":5860,"Actual Price(INR)/Quintal":5918,"Percentage Difference":"0.98%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":5992,"Actual Price(INR)/Quintal":6052,"Percentage Difference":"0.99%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":6012,"Actual Price(INR)/Quintal":6100,"Percentage Difference":"1.44%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":6050,"Actual Price(INR)/Quintal":5901,"Percentage Difference":"-2.52%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":6065,"Actual Price(INR)/Quintal":5976,"Percentage Difference":"-1.49%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":5921,"Actual Price(INR)/Quintal":6013,"Percentage Difference":"1.53%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":5899,"Actual Price(INR)/Quintal":5786,"Percentage Difference":"-1.95%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":5874,"Actual Price(INR)/Quintal":6050,"Percentage Difference":"2.91%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":5930,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"-0.51%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":5906,"Actual Price(INR)/Quintal":5812,"Percentage Difference":"-1.62%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":5893,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"0.12%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":5857,"Actual Price(INR)/Quintal":6040,"Percentage Difference":"3.03%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":5999,"Actual Price(INR)/Quintal":5929,"Percentage Difference":"-1.18%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":5925,"Actual Price(INR)/Quintal":5961,"Percentage Difference":"0.60%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":5853,"Actual Price(INR)/Quintal":5860,"Percentage Difference":"0.12%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":5816,"Actual Price(INR)/Quintal":5809,"Percentage Difference":"-0.12%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":5528,"Actual Price(INR)/Quintal":5616,"Percentage Difference":"1.57%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":5515,"Actual Price(INR)/Quintal":5262,"Percentage Difference":"-4.81%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":5904,"Actual Price(INR)/Quintal":6108,"Percentage Difference":"3.34%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":5685,"Actual Price(INR)/Quintal":5600,"Percentage Difference":"-1.52%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":5664,"Actual Price(INR)/Quintal":5864,"Percentage Difference":"3.41%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":5918,"Actual Price(INR)/Quintal":5793,"Percentage Difference":"-2.16%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":5841,"Actual Price(INR)/Quintal":5848,"Percentage Difference":"0.12%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":5878,"Actual Price(INR)/Quintal":5784,"Percentage Difference":"-1.63%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6050,"Actual Price(INR)/Quintal":6209,"Percentage Difference":"2.56%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6218,"Actual Price(INR)/Quintal":6190,"Percentage Difference":"-0.45%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":6238,"Actual Price(INR)/Quintal":6202,"Percentage Difference":"-0.58%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":6230,"Actual Price(INR)/Quintal":6214,"Percentage Difference":"-0.26%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":6297,"Actual Price(INR)/Quintal":6248,"Percentage Difference":"-0.78%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":6272,"Actual Price(INR)/Quintal":6226,"Percentage Difference":"-0.74%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":6214,"Actual Price(INR)/Quintal":6178,"Percentage Difference":"-0.58%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":6188,"Actual Price(INR)/Quintal":6190,"Percentage Difference":"0.03%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":6209,"Actual Price(INR)/Quintal":6307,"Percentage Difference":"1.55%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":6217,"Actual Price(INR)/Quintal":6277,"Percentage Difference":"0.96%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":6271,"Actual Price(INR)/Quintal":6235,"Percentage Difference":"-0.58%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":6267,"Actual Price(INR)/Quintal":6265,"Percentage Difference":"-0.03%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":6279,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"-1.27%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":6169,"Actual Price(INR)/Quintal":6080,"Percentage Difference":"-1.46%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6062,"Actual Price(INR)/Quintal":6183,"Percentage Difference":"1.96%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6027,"Actual Price(INR)/Quintal":6145,"Percentage Difference":"1.92%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6084,"Actual Price(INR)/Quintal":6112,"Percentage Difference":"0.46%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":6392,"Actual Price(INR)/Quintal":6722,"Percentage Difference":"4.91%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":6463,"Actual Price(INR)/Quintal":6680,"Percentage Difference":"3.25%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":6381,"Actual Price(INR)/Quintal":6297,"Percentage Difference":"-1.33%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":6322,"Actual Price(INR)/Quintal":6392,"Percentage Difference":"1.10%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":6296,"Actual Price(INR)/Quintal":6648,"Percentage Difference":"5.29%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":6312,"Actual Price(INR)/Quintal":6618,"Percentage Difference":"4.62%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":6273,"Actual Price(INR)/Quintal":6738,"Percentage Difference":"6.90%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":6831,"Actual Price(INR)/Quintal":6848,"Percentage Difference":"0.25%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":6851,"Actual Price(INR)/Quintal":6883,"Percentage Difference":"0.46%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":6861,"Actual Price(INR)/Quintal":6887,"Percentage Difference":"0.38%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":6923,"Actual Price(INR)/Quintal":6950,"Percentage Difference":"0.39%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":6904,"Actual Price(INR)/Quintal":7200,"Percentage Difference":"4.11%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":7053,"Actual Price(INR)/Quintal":7734,"Percentage Difference":"8.81%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":8054,"Actual Price(INR)/Quintal":8076,"Percentage Difference":"0.27%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":8059,"Actual Price(INR)/Quintal":8225,"Percentage Difference":"2.02%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":8264,"Actual Price(INR)/Quintal":8298,"Percentage Difference":"0.41%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":8249,"Actual Price(INR)/Quintal":8311,"Percentage Difference":"0.75%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":8272,"Actual Price(INR)/Quintal":8412,"Percentage Difference":"1.66%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":8269,"Actual Price(INR)/Quintal":7810,"Percentage Difference":"-5.88%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":7897,"Actual Price(INR)/Quintal":7530,"Percentage Difference":"-4.87%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":7429,"Actual Price(INR)/Quintal":7861,"Percentage Difference":"5.50%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":7479,"Actual Price(INR)/Quintal":7295,"Percentage Difference":"-2.52%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":7232,"Actual Price(INR)/Quintal":6895,"Percentage Difference":"-4.89%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":6616,"Actual Price(INR)/Quintal":7010,"Percentage Difference":"5.62%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":7062,"Actual Price(INR)/Quintal":7255,"Percentage Difference":"2.66%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":7032,"Actual Price(INR)/Quintal":7132,"Percentage Difference":"1.40%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":7278,"Actual Price(INR)/Quintal":7372,"Percentage Difference":"1.28%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":7307,"Actual Price(INR)/Quintal":6795,"Percentage Difference":"-7.53%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":6417,"Actual Price(INR)/Quintal":6688,"Percentage Difference":"4.05%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":6620,"Actual Price(INR)/Quintal":6745,"Percentage Difference":"1.85%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":6339,"Actual Price(INR)/Quintal":6269,"Percentage Difference":"-1.12%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":6089,"Actual Price(INR)/Quintal":6021,"Percentage Difference":"-1.13%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":5186,"Actual Price(INR)/Quintal":5203,"Percentage Difference":"0.33%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":5275,"Actual Price(INR)/Quintal":5433,"Percentage Difference":"2.91%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":5470,"Actual Price(INR)/Quintal":5662,"Percentage Difference":"3.39%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":5768,"Actual Price(INR)/Quintal":5990,"Percentage Difference":"3.71%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":5945,"Actual Price(INR)/Quintal":6059,"Percentage Difference":"1.88%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":5977,"Actual Price(INR)/Quintal":6127,"Percentage Difference":"2.45%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":6021,"Actual Price(INR)/Quintal":6064,"Percentage Difference":"0.71%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":6041,"Actual Price(INR)/Quintal":6122,"Percentage Difference":"1.32%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":6110,"Actual Price(INR)/Quintal":5838,"Percentage Difference":"-4.66%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":5924,"Actual Price(INR)/Quintal":6034,"Percentage Difference":"1.82%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":5951,"Actual Price(INR)/Quintal":5975,"Percentage Difference":"0.40%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":5959,"Actual Price(INR)/Quintal":5809,"Percentage Difference":"-2.58%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":5828,"Actual Price(INR)/Quintal":5840,"Percentage Difference":"0.21%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":5811,"Actual Price(INR)/Quintal":5772,"Percentage Difference":"-0.68%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":5876,"Actual Price(INR)/Quintal":5947,"Percentage Difference":"1.19%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":5888,"Actual Price(INR)/Quintal":5771,"Percentage Difference":"-2.03%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 5322,
    "Actual Price(INR)/Quintal": 5376,
    "Percentage Difference": "1.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 5313,
    "Actual Price(INR)/Quintal": 5619,
    "Percentage Difference": "5.45%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 5518,
    "Actual Price(INR)/Quintal": 5841,
    "Percentage Difference": "5.53%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 5764,
    "Actual Price(INR)/Quintal": 5813,
    "Percentage Difference": "0.84%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 5741,
    "Actual Price(INR)/Quintal": 5623,
    "Percentage Difference": "-2.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 5653,
    "Actual Price(INR)/Quintal": 5664,
    "Percentage Difference": "0.19%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 5624,
    "Actual Price(INR)/Quintal": 5905,
    "Percentage Difference": "4.76%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 5710,
    "Actual Price(INR)/Quintal": 5771,
    "Percentage Difference": "1.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 5771,
    "Actual Price(INR)/Quintal": 5884,
    "Percentage Difference": "1.92%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 5718,
    "Actual Price(INR)/Quintal": 6003,
    "Percentage Difference": "4.75%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 5913,
    "Actual Price(INR)/Quintal": 6007,
    "Percentage Difference": "1.56%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 5919,
    "Actual Price(INR)/Quintal": 6157,
    "Percentage Difference": "3.87%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 6073,
    "Actual Price(INR)/Quintal": 5990,
    "Percentage Difference": "-1.39%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 6070,
    "Actual Price(INR)/Quintal": 5642,
    "Percentage Difference": "-7.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 5773,
    "Actual Price(INR)/Quintal": 5700,
    "Percentage Difference": "-1.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 5699,
    "Actual Price(INR)/Quintal": 5801,
    "Percentage Difference": "1.76%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 5766,
    "Actual Price(INR)/Quintal": 6085,
    "Percentage Difference": "5.24%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 6006,
    "Actual Price(INR)/Quintal": 6095,
    "Percentage Difference": "1.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 6089,
    "Actual Price(INR)/Quintal": 5882,
    "Percentage Difference": "-3.52%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 5635,
    "Actual Price(INR)/Quintal": 5900,
    "Percentage Difference": "4.49%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 5980,
    "Actual Price(INR)/Quintal": 6050,
    "Percentage Difference": "1.16%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 6009,
    "Actual Price(INR)/Quintal": 6008,
    "Percentage Difference": "-0.02%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 6076,
    "Actual Price(INR)/Quintal": 6025,
    "Percentage Difference": "-0.85%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 6018,
    "Actual Price(INR)/Quintal": 5900,
    "Percentage Difference": "-2.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 6012,
    "Actual Price(INR)/Quintal": 5878,
    "Percentage Difference": "-2.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 5810,
    "Actual Price(INR)/Quintal": 5702,
    "Percentage Difference": "-1.89%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 5685,
    "Actual Price(INR)/Quintal": 5664,
    "Percentage Difference": "-0.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 5575,
    "Actual Price(INR)/Quintal": 5639,
    "Percentage Difference": "1.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 5527,
    "Actual Price(INR)/Quintal": 5890,
    "Percentage Difference": "6.16%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 5838,
    "Actual Price(INR)/Quintal": 6086,
    "Percentage Difference": "4.07%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 6160,
    "Actual Price(INR)/Quintal": 6460,
    "Percentage Difference": "4.64%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 6614,
    "Actual Price(INR)/Quintal": 6330,
    "Percentage Difference": "-4.49%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 6096,
    "Actual Price(INR)/Quintal": 6117,
    "Percentage Difference": "0.34%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 6116,
    "Actual Price(INR)/Quintal": 6034,
    "Percentage Difference": "-1.36%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 6090,
    "Actual Price(INR)/Quintal": 5812,
    "Percentage Difference": "-4.78%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 5718,
    "Actual Price(INR)/Quintal": 5720,
    "Percentage Difference": "0.03%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 5691,
    "Actual Price(INR)/Quintal": 5500,
    "Percentage Difference": "-3.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 5514,
    "Actual Price(INR)/Quintal": 5771,
    "Percentage Difference": "4.45%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 5544,
    "Actual Price(INR)/Quintal": 5817,
    "Percentage Difference": "4.69%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 5953,
    "Actual Price(INR)/Quintal": 5966,
    "Percentage Difference": "0.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 5901,
    "Actual Price(INR)/Quintal": 5784,
    "Percentage Difference": "-2.02%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 6062,
    "Actual Price(INR)/Quintal": 5791,
    "Percentage Difference": "-4.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 6347,
    "Actual Price(INR)/Quintal": 6124,
    "Percentage Difference": "-3.64%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 6198,
    "Actual Price(INR)/Quintal": 6403,
    "Percentage Difference": "3.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 6347,
    "Actual Price(INR)/Quintal": 6612,
    "Percentage Difference": "4.01%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 6254,
    "Actual Price(INR)/Quintal": 6293,
    "Percentage Difference": "0.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 6388,
    "Actual Price(INR)/Quintal": 6318,
    "Percentage Difference": "-1.11%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 6255,
    "Actual Price(INR)/Quintal": 6325,
    "Percentage Difference": "1.11%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 5309,
    "Actual Price(INR)/Quintal": 5260,
    "Percentage Difference": "-0.93%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 5250,
    "Actual Price(INR)/Quintal": 5365,
    "Percentage Difference": "2.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 5279,
    "Actual Price(INR)/Quintal": 5359,
    "Percentage Difference": "1.49%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 5275,
    "Actual Price(INR)/Quintal": 5565,
    "Percentage Difference": "5.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 5491,
    "Actual Price(INR)/Quintal": 5606,
    "Percentage Difference": "2.05%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 5577,
    "Actual Price(INR)/Quintal": 5382,
    "Percentage Difference": "-3.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 5347,
    "Actual Price(INR)/Quintal": 5511,
    "Percentage Difference": "2.98%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 5542,
    "Actual Price(INR)/Quintal": 5746,
    "Percentage Difference": "3.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 5522,
    "Actual Price(INR)/Quintal": 5422,
    "Percentage Difference": "-1.84%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 5474,
    "Actual Price(INR)/Quintal": 5718,
    "Percentage Difference": "4.27%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 5789,
    "Actual Price(INR)/Quintal": 5694,
    "Percentage Difference": "-1.67%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 5642,
    "Actual Price(INR)/Quintal": 5421,
    "Percentage Difference": "-4.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 5494,
    "Actual Price(INR)/Quintal": 5726,
    "Percentage Difference": "4.05%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 5598,
    "Actual Price(INR)/Quintal": 5909,
    "Percentage Difference": "5.26%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 5830,
    "Actual Price(INR)/Quintal": 5860,
    "Percentage Difference": "0.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 5893,
    "Actual Price(INR)/Quintal": 6200,
    "Percentage Difference": "4.95%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 5841,
    "Actual Price(INR)/Quintal": 5995,
    "Percentage Difference": "2.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 5901,
    "Actual Price(INR)/Quintal": 6341,
    "Percentage Difference": "6.94%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 6602,
    "Actual Price(INR)/Quintal": 6025,
    "Percentage Difference": "-9.58%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 6037,
    "Actual Price(INR)/Quintal": 6259,
    "Percentage Difference": "3.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 6070,
    "Actual Price(INR)/Quintal": 6185,
    "Percentage Difference": "1.86%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 5965,
    "Actual Price(INR)/Quintal": 6151,
    "Percentage Difference": "3.02%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 6167,
    "Actual Price(INR)/Quintal": 5965,
    "Percentage Difference": "-3.39%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 5940,
    "Actual Price(INR)/Quintal": 6013,
    "Percentage Difference": "1.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 6074,
    "Actual Price(INR)/Quintal": 5795,
    "Percentage Difference": "-4.81%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 5798,
    "Actual Price(INR)/Quintal": 5592,
    "Percentage Difference": "-3.68%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 5686,
    "Actual Price(INR)/Quintal": 5697,
    "Percentage Difference": "0.19%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 5672,
    "Actual Price(INR)/Quintal": 5249,
    "Percentage Difference": "-8.06%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 5663,
    "Actual Price(INR)/Quintal": 5263,
    "Percentage Difference": "-7.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 5100,
    "Actual Price(INR)/Quintal": 5092,
    "Percentage Difference": "-0.16%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 5045,
    "Actual Price(INR)/Quintal": 4984,
    "Percentage Difference": "-1.22%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 5058,
    "Actual Price(INR)/Quintal": 4916,
    "Percentage Difference": "-2.89%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 4669,
    "Actual Price(INR)/Quintal": 4720,
    "Percentage Difference": "1.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 4694,
    "Actual Price(INR)/Quintal": 4945,
    "Percentage Difference": "5.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 4799,
    "Actual Price(INR)/Quintal": 4780,
    "Percentage Difference": "-0.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 4807,
    "Actual Price(INR)/Quintal": 4724,
    "Percentage Difference": "-1.76%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 4786,
    "Actual Price(INR)/Quintal": 4565,
    "Percentage Difference": "-4.84%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 4565,
    "Actual Price(INR)/Quintal": 4452,
    "Percentage Difference": "-2.54%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 4489,
    "Actual Price(INR)/Quintal": 4666,
    "Percentage Difference": "3.79%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 4562,
    "Actual Price(INR)/Quintal": 4798,
    "Percentage Difference": "4.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 4789,
    "Actual Price(INR)/Quintal": 5034,
    "Percentage Difference": "4.87%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 5162,
    "Actual Price(INR)/Quintal": 5311,
    "Percentage Difference": "2.81%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 5327,
    "Actual Price(INR)/Quintal": 5384,
    "Percentage Difference": "1.06%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 5377,
    "Actual Price(INR)/Quintal": 5382,
    "Percentage Difference": "0.09%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 5343,
    "Actual Price(INR)/Quintal": 5297,
    "Percentage Difference": "-0.87%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 5245,
    "Actual Price(INR)/Quintal": 5327,
    "Percentage Difference": "1.54%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 5261,
    "Actual Price(INR)/Quintal": 5331,
    "Percentage Difference": "1.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 5241,
    "Actual Price(INR)/Quintal": 5403,
    "Percentage Difference": "3.00%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 4887,
    "Actual Price(INR)/Quintal": 4842,
    "Percentage Difference": "-0.93%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 4845,
    "Actual Price(INR)/Quintal": 4937,
    "Percentage Difference": "1.86%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 4867,
    "Actual Price(INR)/Quintal": 4941,
    "Percentage Difference": "1.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 4854,
    "Actual Price(INR)/Quintal": 5010,
    "Percentage Difference": "3.11%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 4997,
    "Actual Price(INR)/Quintal": 4972,
    "Percentage Difference": "-0.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 4995,
    "Actual Price(INR)/Quintal": 5095,
    "Percentage Difference": "1.96%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 5040,
    "Actual Price(INR)/Quintal": 5203,
    "Percentage Difference": "3.13%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 5023,
    "Actual Price(INR)/Quintal": 5303,
    "Percentage Difference": "5.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 5234,
    "Actual Price(INR)/Quintal": 5317,
    "Percentage Difference": "1.56%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 5222,
    "Actual Price(INR)/Quintal": 5497,
    "Percentage Difference": "5.00%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 5312,
    "Actual Price(INR)/Quintal": 5407,
    "Percentage Difference": "1.76%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 5299,
    "Actual Price(INR)/Quintal": 5530,
    "Percentage Difference": "4.18%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 5435,
    "Actual Price(INR)/Quintal": 5480,
    "Percentage Difference": "0.82%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 5428,
    "Actual Price(INR)/Quintal": 5658,
    "Percentage Difference": "4.07%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 5408,
    "Actual Price(INR)/Quintal": 5359,
    "Percentage Difference": "-0.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 5396,
    "Actual Price(INR)/Quintal": 5423,
    "Percentage Difference": "0.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 5463,
    "Actual Price(INR)/Quintal": 5277,
    "Percentage Difference": "-3.52%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 5216,
    "Actual Price(INR)/Quintal": 5255,
    "Percentage Difference": "0.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 5006,
    "Actual Price(INR)/Quintal": 5257,
    "Percentage Difference": "4.77%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 5208,
    "Actual Price(INR)/Quintal": 5115,
    "Percentage Difference": "-1.82%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 5110,
    "Actual Price(INR)/Quintal": 5014,
    "Percentage Difference": "-1.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 5029,
    "Actual Price(INR)/Quintal": 5211,
    "Percentage Difference": "3.49%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 5240,
    "Actual Price(INR)/Quintal": 5182,
    "Percentage Difference": "-1.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 5227,
    "Actual Price(INR)/Quintal": 4948,
    "Percentage Difference": "-5.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul  Wk1",
    "Predicted Price(INR)/Quintal": 4952,
    "Actual Price(INR)/Quintal": 5264,
    "Percentage Difference": "5.93%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul  Wk2",
    "Predicted Price(INR)/Quintal": 4979,
    "Actual Price(INR)/Quintal": 5163,
    "Percentage Difference": "3.56%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul  Wk3",
    "Predicted Price(INR)/Quintal": 5224,
    "Actual Price(INR)/Quintal": 5126,
    "Percentage Difference": "-1.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul  Wk4",
    "Predicted Price(INR)/Quintal": 5222,
    "Actual Price(INR)/Quintal": 4977,
    "Percentage Difference": "-4.92%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 5057,
    "Actual Price(INR)/Quintal": 5375,
    "Percentage Difference": "5.92%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 5250,
    "Actual Price(INR)/Quintal": 4969,
    "Percentage Difference": "-5.66%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 4937,
    "Actual Price(INR)/Quintal": 4825,
    "Percentage Difference": "-2.32%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 4851,
    "Actual Price(INR)/Quintal": 5016,
    "Percentage Difference": "3.29%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 5106,
    "Actual Price(INR)/Quintal": 5170,
    "Percentage Difference": "1.24%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 5104,
    "Actual Price(INR)/Quintal": 4874,
    "Percentage Difference": "-4.72%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 4991,
    "Actual Price(INR)/Quintal": 4695,
    "Percentage Difference": "-6.30%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 4576,
    "Actual Price(INR)/Quintal": 4655,
    "Percentage Difference": "1.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 4570,
    "Actual Price(INR)/Quintal": 4749,
    "Percentage Difference": "3.77%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 4575,
    "Actual Price(INR)/Quintal": 4463,
    "Percentage Difference": "-2.51%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 4410,
    "Actual Price(INR)/Quintal": 4648,
    "Percentage Difference": "5.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 4606,
    "Actual Price(INR)/Quintal": 4724,
    "Percentage Difference": "2.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 4657,
    "Actual Price(INR)/Quintal": 4594,
    "Percentage Difference": "-1.37%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 4546,
    "Actual Price(INR)/Quintal": 4626,
    "Percentage Difference": "1.73%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 4552,
    "Actual Price(INR)/Quintal": 4780,
    "Percentage Difference": "4.77%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 4768,
    "Actual Price(INR)/Quintal": 5012,
    "Percentage Difference": "4.87%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 4843,
    "Actual Price(INR)/Quintal": 5084,
    "Percentage Difference": "4.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 5072,
    "Actual Price(INR)/Quintal": 5258,
    "Percentage Difference": "3.54%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 5271,
    "Actual Price(INR)/Quintal": 5384,
    "Percentage Difference": "2.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 5373,
    "Actual Price(INR)/Quintal": 5378,
    "Percentage Difference": "0.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 7002,
    "Actual Price(INR)/Quintal": 7286,
    "Percentage Difference": "3.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 7443,
    "Actual Price(INR)/Quintal": 7559,
    "Percentage Difference": "1.53%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 7469,
    "Actual Price(INR)/Quintal": 7300,
    "Percentage Difference": "-2.32%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 7428,
    "Actual Price(INR)/Quintal": 7526,
    "Percentage Difference": "1.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 8048,
    "Actual Price(INR)/Quintal": 8944,
    "Percentage Difference": "10.02%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 8016,
    "Actual Price(INR)/Quintal": 7497,
    "Percentage Difference": "-6.92%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 8154,
    "Actual Price(INR)/Quintal": 7656,
    "Percentage Difference": "-6.50%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 7537,
    "Actual Price(INR)/Quintal": 7253,
    "Percentage Difference": "-3.92%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 7441,
    "Actual Price(INR)/Quintal": 7651,
    "Percentage Difference": "2.74%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 7450,
    "Actual Price(INR)/Quintal": 7330,
    "Percentage Difference": "-1.64%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 7391,
    "Actual Price(INR)/Quintal": 7637,
    "Percentage Difference": "3.22%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 7536,
    "Actual Price(INR)/Quintal": 7668,
    "Percentage Difference": "1.72%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 7566,
    "Actual Price(INR)/Quintal": 7682,
    "Percentage Difference": "1.51%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 7676,
    "Actual Price(INR)/Quintal": 7669,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 7677,
    "Actual Price(INR)/Quintal": 8279,
    "Percentage Difference": "7.27%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 7812,
    "Actual Price(INR)/Quintal": 7121,
    "Percentage Difference": "-9.70%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 7112,
    "Actual Price(INR)/Quintal": 7729,
    "Percentage Difference": "7.98%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 7732,
    "Actual Price(INR)/Quintal": 7353,
    "Percentage Difference": "-5.15%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 7343,
    "Actual Price(INR)/Quintal": 7588,
    "Percentage Difference": "3.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 7560,
    "Actual Price(INR)/Quintal": 7589,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 7565,
    "Actual Price(INR)/Quintal": 7420,
    "Percentage Difference": "-1.95%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 7603,
    "Actual Price(INR)/Quintal": 7697,
    "Percentage Difference": "1.22%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 7781,
    "Actual Price(INR)/Quintal": 7055,
    "Percentage Difference": "-10.29%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 7003,
    "Actual Price(INR)/Quintal": 6238,
    "Percentage Difference": "-12.26%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 6198,
    "Actual Price(INR)/Quintal": 6640,
    "Percentage Difference": "6.66%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 6509,
    "Actual Price(INR)/Quintal": 7055,
    "Percentage Difference": "7.74%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 7019,
    "Actual Price(INR)/Quintal": 6249,
    "Percentage Difference": "-12.32%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 6081,
    "Actual Price(INR)/Quintal": 6295,
    "Percentage Difference": "3.40%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 6211,
    "Actual Price(INR)/Quintal": 5857,
    "Percentage Difference": "-6.04%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 5813,
    "Actual Price(INR)/Quintal": 5722,
    "Percentage Difference": "-1.59%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 5023,
    "Actual Price(INR)/Quintal": 4784,
    "Percentage Difference": "-4.99%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 4888,
    "Actual Price(INR)/Quintal": 4786,
    "Percentage Difference": "-2.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 4844,
    "Actual Price(INR)/Quintal": 4938,
    "Percentage Difference": "1.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 4655,
    "Actual Price(INR)/Quintal": 4911,
    "Percentage Difference": "5.21%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 4979,
    "Actual Price(INR)/Quintal": 5359,
    "Percentage Difference": "7.09%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 5389,
    "Actual Price(INR)/Quintal": 5058,
    "Percentage Difference": "-6.54%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 5005,
    "Actual Price(INR)/Quintal": 5011,
    "Percentage Difference": "0.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 5095,
    "Actual Price(INR)/Quintal": 4996,
    "Percentage Difference": "-1.78%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 5090,
    "Actual Price(INR)/Quintal": 5057,
    "Percentage Difference": "-0.65%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 5017,
    "Actual Price(INR)/Quintal": 5135,
    "Percentage Difference": "2.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 5046,
    "Actual Price(INR)/Quintal": 4904,
    "Percentage Difference": "-2.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 4967,
    "Actual Price(INR)/Quintal": 4905,
    "Percentage Difference": "-1.26%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 4911,
    "Actual Price(INR)/Quintal": 4833,
    "Percentage Difference": "-1.61%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 4830,
    "Actual Price(INR)/Quintal": 4852,
    "Percentage Difference": "0.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 4864,
    "Actual Price(INR)/Quintal": 4873,
    "Percentage Difference": "0.18%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 4890,
    "Actual Price(INR)/Quintal": 4802,
    "Percentage Difference": "-1.83%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 4857,
    "Actual Price(INR)/Quintal": 4847,
    "Percentage Difference": "-0.21%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 4856,
    "Actual Price(INR)/Quintal": 4840,
    "Percentage Difference": "-0.33%",
    "YEAR": 2016
  }
];
