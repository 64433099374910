export const Sunflower = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":4621,"Actual Price(INR)/Quintal":4683,"Percentage Difference":"1.32%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":4669,"Actual Price(INR)/Quintal":4807,"Percentage Difference":"2.87%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":4859,"Actual Price(INR)/Quintal":4953,"Percentage Difference":"1.90%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":4809,"Actual Price(INR)/Quintal":4950,"Percentage Difference":"2.85%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":4889,"Actual Price(INR)/Quintal":4703,"Percentage Difference":"-3.95%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":4805,"Actual Price(INR)/Quintal":5142,"Percentage Difference":"6.55%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":5141,"Actual Price(INR)/Quintal":5228,"Percentage Difference":"1.66%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":5149,"Actual Price(INR)/Quintal":5072,"Percentage Difference":"-1.52%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":5172,"Actual Price(INR)/Quintal":5145,"Percentage Difference":"-0.52%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":5080,"Actual Price(INR)/Quintal":4936,"Percentage Difference":"-2.92%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":4554,"Actual Price(INR)/Quintal":4600,"Percentage Difference":"1.00%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":4474,"Actual Price(INR)/Quintal":4256,"Percentage Difference":"-5.12%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":4333,"Actual Price(INR)/Quintal":4497,"Percentage Difference":"3.65%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":4349,"Actual Price(INR)/Quintal":4246,"Percentage Difference":"-2.43%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":4369,"Actual Price(INR)/Quintal":4377,"Percentage Difference":"0.18%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":4354,"Actual Price(INR)/Quintal":4375,"Percentage Difference":"0.48%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":4366,"Actual Price(INR)/Quintal":4400,"Percentage Difference":"0.77%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":4374,"Actual Price(INR)/Quintal":4371,"Percentage Difference":"-0.07%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":4236,"Actual Price(INR)/Quintal":4306,"Percentage Difference":"1.63%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":4313,"Actual Price(INR)/Quintal":4362,"Percentage Difference":"1.12%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":4345,"Actual Price(INR)/Quintal":4447,"Percentage Difference":"2.29%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":4473,"Actual Price(INR)/Quintal":4593,"Percentage Difference":"2.61%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":4569,"Actual Price(INR)/Quintal":4754,"Percentage Difference":"3.89%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":4720,"Actual Price(INR)/Quintal":4675,"Percentage Difference":"-0.96%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":4413,"Actual Price(INR)/Quintal":4363,"Percentage Difference":"-1.15%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":4418,"Actual Price(INR)/Quintal":4506,"Percentage Difference":"1.95%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":4565,"Actual Price(INR)/Quintal":4624,"Percentage Difference":"1.28%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":4518,"Actual Price(INR)/Quintal":4589,"Percentage Difference":"1.55%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":4526,"Actual Price(INR)/Quintal":4624,"Percentage Difference":"2.12%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":4583,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":4530,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":4511,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":4568,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":5738,"Actual Price(INR)/Quintal":5876,"Percentage Difference":"2.35%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":5806,"Actual Price(INR)/Quintal":5785,"Percentage Difference":"-0.36%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":5794,"Actual Price(INR)/Quintal":5750,"Percentage Difference":"-0.77%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":5532,"Actual Price(INR)/Quintal":5527,"Percentage Difference":"-0.09%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":5547,"Actual Price(INR)/Quintal":5741,"Percentage Difference":"3.38%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":5602,"Actual Price(INR)/Quintal":5810,"Percentage Difference":"3.58%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":5593,"Actual Price(INR)/Quintal":5684,"Percentage Difference":"1.60%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":5585,"Actual Price(INR)/Quintal":5595,"Percentage Difference":"0.18%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":5686,"Actual Price(INR)/Quintal":5390,"Percentage Difference":"-5.49%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":5279,"Actual Price(INR)/Quintal":5049,"Percentage Difference":"-4.56%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":5242,"Actual Price(INR)/Quintal":5129,"Percentage Difference":"-2.20%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":5039,"Actual Price(INR)/Quintal":5011,"Percentage Difference":"-0.56%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":5003,"Actual Price(INR)/Quintal":5017,"Percentage Difference":"0.28%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":4613,"Actual Price(INR)/Quintal":4699,"Percentage Difference":"1.83%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":4618,"Actual Price(INR)/Quintal":4565,"Percentage Difference":"-1.16%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":4535,"Actual Price(INR)/Quintal":4581,"Percentage Difference":"1.00%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":4593,"Actual Price(INR)/Quintal":4504,"Percentage Difference":"-1.98%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":4450,"Actual Price(INR)/Quintal":4528,"Percentage Difference":"1.72%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":4564,"Actual Price(INR)/Quintal":4582,"Percentage Difference":"0.39%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":4555,"Actual Price(INR)/Quintal":4564,"Percentage Difference":"0.20%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":4508,"Actual Price(INR)/Quintal":4620,"Percentage Difference":"2.42%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":4576,"Actual Price(INR)/Quintal":4580,"Percentage Difference":"0.09%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":4568,"Actual Price(INR)/Quintal":4603,"Percentage Difference":"0.76%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":4580,"Actual Price(INR)/Quintal":4573,"Percentage Difference":"-0.15%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":4533,"Actual Price(INR)/Quintal":4667,"Percentage Difference":"2.87%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":4576,"Actual Price(INR)/Quintal":4758,"Percentage Difference":"3.83%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":4639,"Actual Price(INR)/Quintal":4707,"Percentage Difference":"1.44%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":4643,"Actual Price(INR)/Quintal":4526,"Percentage Difference":"-2.59%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":4523,"Actual Price(INR)/Quintal":4647,"Percentage Difference":"2.67%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":4496,"Actual Price(INR)/Quintal":4717,"Percentage Difference":"4.69%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":4627,"Actual Price(INR)/Quintal":4751,"Percentage Difference":"2.61%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":4773,"Actual Price(INR)/Quintal":4685,"Percentage Difference":"-1.88%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":4683,"Actual Price(INR)/Quintal":4675,"Percentage Difference":"-0.17%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":4694,"Actual Price(INR)/Quintal":4583,"Percentage Difference":"-2.42%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":4496,"Actual Price(INR)/Quintal":4535,"Percentage Difference":"0.86%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":4491,"Actual Price(INR)/Quintal":4373,"Percentage Difference":"-2.70%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":4389,"Actual Price(INR)/Quintal":4581,"Percentage Difference":"4.19%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":4503,"Actual Price(INR)/Quintal":4601,"Percentage Difference":"2.13%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":4544,"Actual Price(INR)/Quintal":4655,"Percentage Difference":"2.38%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":4834,"Actual Price(INR)/Quintal":4894,"Percentage Difference":"1.23%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":4794,"Actual Price(INR)/Quintal":4885,"Percentage Difference":"1.86%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":4812,"Actual Price(INR)/Quintal":4712,"Percentage Difference":"-2.12%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":4779,"Actual Price(INR)/Quintal":4889,"Percentage Difference":"2.25%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":4858,"Actual Price(INR)/Quintal":4742,"Percentage Difference":"-2.45%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":4728,"Actual Price(INR)/Quintal":4876,"Percentage Difference":"3.04%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":4745,"Actual Price(INR)/Quintal":4789,"Percentage Difference":"0.92%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":4758,"Actual Price(INR)/Quintal":4626,"Percentage Difference":"-2.85%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":4642,"Actual Price(INR)/Quintal":4537,"Percentage Difference":"-2.31%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":5057,"Actual Price(INR)/Quintal":5209,"Percentage Difference":"2.92%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":5183,"Actual Price(INR)/Quintal":5358,"Percentage Difference":"3.27%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":5472,"Actual Price(INR)/Quintal":5618,"Percentage Difference":"2.60%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":5811,"Actual Price(INR)/Quintal":6002,"Percentage Difference":"3.18%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":6059,"Actual Price(INR)/Quintal":6169,"Percentage Difference":"1.78%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":6064,"Actual Price(INR)/Quintal":6146,"Percentage Difference":"1.33%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":6323,"Actual Price(INR)/Quintal":6385,"Percentage Difference":"0.97%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":6349,"Actual Price(INR)/Quintal":6482,"Percentage Difference":"2.05%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6670,"Actual Price(INR)/Quintal":6774,"Percentage Difference":"1.54%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":7433,"Actual Price(INR)/Quintal":7556,"Percentage Difference":"1.63%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":7800,"Actual Price(INR)/Quintal":7790,"Percentage Difference":"-0.13%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":7834,"Actual Price(INR)/Quintal":7666,"Percentage Difference":"-2.19%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":7633,"Actual Price(INR)/Quintal":7362,"Percentage Difference":"-3.68%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":7298,"Actual Price(INR)/Quintal":6952,"Percentage Difference":"-4.98%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":6925,"Actual Price(INR)/Quintal":7181,"Percentage Difference":"3.56%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":6969,"Actual Price(INR)/Quintal":7150,"Percentage Difference":"2.53%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":7133,"Actual Price(INR)/Quintal":7385,"Percentage Difference":"3.41%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":7311,"Actual Price(INR)/Quintal":7126,"Percentage Difference":"-2.60%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":7370,"Actual Price(INR)/Quintal":7423,"Percentage Difference":"0.71%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":7387,"Actual Price(INR)/Quintal":7400,"Percentage Difference":"0.18%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":7012,"Actual Price(INR)/Quintal":7052,"Percentage Difference":"0.57%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":7066,"Actual Price(INR)/Quintal":7062,"Percentage Difference":"-0.06%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":7011,"Actual Price(INR)/Quintal":6881,"Percentage Difference":"-1.89%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":6035,"Actual Price(INR)/Quintal":6080,"Percentage Difference":"0.74%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":6167,"Actual Price(INR)/Quintal":6300,"Percentage Difference":"2.11%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":6369,"Actual Price(INR)/Quintal":6611,"Percentage Difference":"3.66%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":6409,"Actual Price(INR)/Quintal":6624,"Percentage Difference":"3.25%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":6453,"Actual Price(INR)/Quintal":6458,"Percentage Difference":"0.08%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":6410,"Actual Price(INR)/Quintal":6419,"Percentage Difference":"0.14%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":6450,"Actual Price(INR)/Quintal":6650,"Percentage Difference":"3.01%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":6458,"Actual Price(INR)/Quintal":6309,"Percentage Difference":"-2.36%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6487,"Actual Price(INR)/Quintal":6528,"Percentage Difference":"0.63%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6288,"Actual Price(INR)/Quintal":6101,"Percentage Difference":"-3.07%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":5206,"Actual Price(INR)/Quintal":5145,"Percentage Difference":"-1.19%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":5496,"Actual Price(INR)/Quintal":5384,"Percentage Difference":"-2.08%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":4683,"Actual Price(INR)/Quintal":4596,"Percentage Difference":"-1.89%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":4694,"Actual Price(INR)/Quintal":4825,"Percentage Difference":"2.72%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":4773,"Actual Price(INR)/Quintal":5032,"Percentage Difference":"5.15%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":5444,"Actual Price(INR)/Quintal":5697,"Percentage Difference":"4.44%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":5642,"Actual Price(INR)/Quintal":5566,"Percentage Difference":"-1.37%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":5644,"Actual Price(INR)/Quintal":5796,"Percentage Difference":"2.62%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":5637,"Actual Price(INR)/Quintal":5905,"Percentage Difference":"4.54%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":5939,"Actual Price(INR)/Quintal":5650,"Percentage Difference":"-5.12%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":5666,"Actual Price(INR)/Quintal":5768,"Percentage Difference":"1.77%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":5726,"Actual Price(INR)/Quintal":5712,"Percentage Difference":"-0.25%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":5717,"Actual Price(INR)/Quintal":5762,"Percentage Difference":"0.78%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":5726,"Actual Price(INR)/Quintal":5765,"Percentage Difference":"0.68%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":5765,"Actual Price(INR)/Quintal":5799,"Percentage Difference":"0.59%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":5485,"Actual Price(INR)/Quintal":5478,"Percentage Difference":"-0.13%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":5493,"Actual Price(INR)/Quintal":5636,"Percentage Difference":"2.54%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":5682,"Actual Price(INR)/Quintal":5702,"Percentage Difference":"0.35%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":5705,"Actual Price(INR)/Quintal":5711,"Percentage Difference":"0.11%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":5709,"Actual Price(INR)/Quintal":5837,"Percentage Difference":"2.19%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":5771,"Actual Price(INR)/Quintal":6008,"Percentage Difference":"3.94%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":6359,"Actual Price(INR)/Quintal":6488,"Percentage Difference":"1.99%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":6414,"Actual Price(INR)/Quintal":6455,"Percentage Difference":"0.64%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":6730,"Actual Price(INR)/Quintal":6883,"Percentage Difference":"2.22%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":6965,"Actual Price(INR)/Quintal":6974,"Percentage Difference":"0.13%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":6930,"Actual Price(INR)/Quintal":6883,"Percentage Difference":"-0.68%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":6928,"Actual Price(INR)/Quintal":6681,"Percentage Difference":"-3.70%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":6725,"Actual Price(INR)/Quintal":6662,"Percentage Difference":"-0.95%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":6736,"Actual Price(INR)/Quintal":6589,"Percentage Difference":"-2.23%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":6587,"Actual Price(INR)/Quintal":6609,"Percentage Difference":"0.33%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":6539,"Actual Price(INR)/Quintal":6866,"Percentage Difference":"4.76%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":6710,"Actual Price(INR)/Quintal":6811,"Percentage Difference":"1.48%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":6790,"Actual Price(INR)/Quintal":6808,"Percentage Difference":"0.26%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":6643,"Actual Price(INR)/Quintal":6696,"Percentage Difference":"0.79%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":6601,"Actual Price(INR)/Quintal":6454,"Percentage Difference":"-2.28%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":6412,"Actual Price(INR)/Quintal":6444,"Percentage Difference":"0.50%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":6480,"Actual Price(INR)/Quintal":6398,"Percentage Difference":"-1.28%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":6388,"Actual Price(INR)/Quintal":6470,"Percentage Difference":"1.27%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":6377,"Actual Price(INR)/Quintal":6383,"Percentage Difference":"0.09%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":6026,"Actual Price(INR)/Quintal":5812,"Percentage Difference":"-3.68%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":6037,"Actual Price(INR)/Quintal":5905,"Percentage Difference":"-2.24%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":5900,"Actual Price(INR)/Quintal":6084,"Percentage Difference":"3.02%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":6080,"Actual Price(INR)/Quintal":6201,"Percentage Difference":"1.95%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":6088,"Actual Price(INR)/Quintal":5973,"Percentage Difference":"-1.93%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":6066,"Actual Price(INR)/Quintal":6286,"Percentage Difference":"3.50%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":6360,"Actual Price(INR)/Quintal":6554,"Percentage Difference":"2.96%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6180,"Actual Price(INR)/Quintal":6088,"Percentage Difference":"-1.51%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6123,"Actual Price(INR)/Quintal":6165,"Percentage Difference":"0.68%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":6135,"Actual Price(INR)/Quintal":5915,"Percentage Difference":"-3.72%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":5796,"Actual Price(INR)/Quintal":5606,"Percentage Difference":"-3.39%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":5405,"Actual Price(INR)/Quintal":5381,"Percentage Difference":"-0.45%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":5601,"Actual Price(INR)/Quintal":5620,"Percentage Difference":"0.34%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":5771,"Actual Price(INR)/Quintal":5737,"Percentage Difference":"-0.59%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":5507,"Actual Price(INR)/Quintal":5581,"Percentage Difference":"1.33%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":5559,"Actual Price(INR)/Quintal":5675,"Percentage Difference":"2.04%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":5527,"Actual Price(INR)/Quintal":5572,"Percentage Difference":"0.81%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":5548,"Actual Price(INR)/Quintal":5491,"Percentage Difference":"-1.04%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":5506,"Actual Price(INR)/Quintal":5715,"Percentage Difference":"3.66%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":5809,"Actual Price(INR)/Quintal":6004,"Percentage Difference":"3.25%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":5635,"Actual Price(INR)/Quintal":5531,"Percentage Difference":"-1.88%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":5624,"Actual Price(INR)/Quintal":5599,"Percentage Difference":"-0.45%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":5209,"Actual Price(INR)/Quintal":5150,"Percentage Difference":"-1.15%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":5045,"Actual Price(INR)/Quintal":4922,"Percentage Difference":"-2.50%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":3999,"Actual Price(INR)/Quintal":4102,"Percentage Difference":"2.51%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":4001,"Actual Price(INR)/Quintal":4191,"Percentage Difference":"4.53%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":4096,"Actual Price(INR)/Quintal":4070,"Percentage Difference":"-0.64%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":4025,"Actual Price(INR)/Quintal":4069,"Percentage Difference":"1.08%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":4099,"Actual Price(INR)/Quintal":3932,"Percentage Difference":"-4.25%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":4064,"Actual Price(INR)/Quintal":3966,"Percentage Difference":"-2.47%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":3960,"Actual Price(INR)/Quintal":3924,"Percentage Difference":"-0.92%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":3885,"Actual Price(INR)/Quintal":3860,"Percentage Difference":"-0.65%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":3851,"Actual Price(INR)/Quintal":3739,"Percentage Difference":"-3.00%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":3740,"Actual Price(INR)/Quintal":3588,"Percentage Difference":"-4.24%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":3481,"Actual Price(INR)/Quintal":3446,"Percentage Difference":"-1.02%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":3403,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":3416,"Actual Price(INR)/Quintal":3435,"Percentage Difference":"0.55%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":3415,"Actual Price(INR)/Quintal":3612,"Percentage Difference":"5.45%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":3528,"Actual Price(INR)/Quintal":3618,"Percentage Difference":"2.49%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":3549,"Actual Price(INR)/Quintal":3595,"Percentage Difference":"1.28%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":3552,"Actual Price(INR)/Quintal":3682,"Percentage Difference":"3.53%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":3550,"Actual Price(INR)/Quintal":3596,"Percentage Difference":"1.28%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":3548,"Actual Price(INR)/Quintal":3608,"Percentage Difference":"1.66%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":3585,"Actual Price(INR)/Quintal":3600,"Percentage Difference":"0.42%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":3590,"Actual Price(INR)/Quintal":3830,"Percentage Difference":"6.27%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":3787,"Actual Price(INR)/Quintal":3842,"Percentage Difference":"1.43%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":3767,"Actual Price(INR)/Quintal":3950,"Percentage Difference":"4.63%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":3878,"Actual Price(INR)/Quintal":3924,"Percentage Difference":"1.17%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":3854,"Actual Price(INR)/Quintal":3952,"Percentage Difference":"2.48%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":3840,"Actual Price(INR)/Quintal":3611,"Percentage Difference":"-6.34%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":3585,"Actual Price(INR)/Quintal":3688,"Percentage Difference":"2.79%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":3644,"Actual Price(INR)/Quintal":3715,"Percentage Difference":"1.91%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":3676,"Actual Price(INR)/Quintal":3820,"Percentage Difference":"3.77%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":3851,"Actual Price(INR)/Quintal":3900,"Percentage Difference":"1.26%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":3828,"Actual Price(INR)/Quintal":3910,"Percentage Difference":"2.10%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":3865,"Actual Price(INR)/Quintal":3905,"Percentage Difference":"1.02%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":3981,"Actual Price(INR)/Quintal":4070,"Percentage Difference":"2.19%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":4164,"Actual Price(INR)/Quintal":4338,"Percentage Difference":"4.01%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":4649,"Actual Price(INR)/Quintal":4760,"Percentage Difference":"2.33%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":4665,"Actual Price(INR)/Quintal":4595,"Percentage Difference":"-1.52%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":3757,"Actual Price(INR)/Quintal":3567,"Percentage Difference":"-5.33%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":3576,"Actual Price(INR)/Quintal":3739,"Percentage Difference":"4.36%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":3702,"Actual Price(INR)/Quintal":3780,"Percentage Difference":"2.06%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":4193,"Actual Price(INR)/Quintal":4299,"Percentage Difference":"2.47%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":4298,"Actual Price(INR)/Quintal":4360,"Percentage Difference":"1.42%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":4261,"Actual Price(INR)/Quintal":4369,"Percentage Difference":"2.47%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":4296,"Actual Price(INR)/Quintal":4518,"Percentage Difference":"4.91%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":4482,"Actual Price(INR)/Quintal":4531,"Percentage Difference":"1.08%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":5023,"Actual Price(INR)/Quintal":5151,"Percentage Difference":"2.48%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":5096,"Actual Price(INR)/Quintal":5147,"Percentage Difference":"0.99%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":5153,"Actual Price(INR)/Quintal":5171,"Percentage Difference":"0.35%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":5376,"Actual Price(INR)/Quintal":5471,"Percentage Difference":"1.74%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 3599,
    "Actual Price(INR)/Quintal": 3764,
    "Percentage Difference": "4.38%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 3701,
    "Actual Price(INR)/Quintal": 3844,
    "Percentage Difference": "3.72%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 3796,
    "Actual Price(INR)/Quintal": 3699,
    "Percentage Difference": "-2.62%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 3698,
    "Actual Price(INR)/Quintal": 3898,
    "Percentage Difference": "5.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 3891,
    "Actual Price(INR)/Quintal": 3968,
    "Percentage Difference": "1.94%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 3890,
    "Actual Price(INR)/Quintal": 4034,
    "Percentage Difference": "3.57%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 3999,
    "Actual Price(INR)/Quintal": 3876,
    "Percentage Difference": "-3.17%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 3893,
    "Actual Price(INR)/Quintal": 3803,
    "Percentage Difference": "-2.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 3821,
    "Actual Price(INR)/Quintal": 3817,
    "Percentage Difference": "-0.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 3837,
    "Actual Price(INR)/Quintal": 3841,
    "Percentage Difference": "0.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 3838,
    "Actual Price(INR)/Quintal": 3681,
    "Percentage Difference": "-4.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 3720,
    "Actual Price(INR)/Quintal": 3644,
    "Percentage Difference": "-2.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 3671,
    "Actual Price(INR)/Quintal": 3760,
    "Percentage Difference": "2.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 3723,
    "Actual Price(INR)/Quintal": 3694,
    "Percentage Difference": "-0.79%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 3675,
    "Actual Price(INR)/Quintal": 3677,
    "Percentage Difference": "0.05%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 3625,
    "Actual Price(INR)/Quintal": 3652,
    "Percentage Difference": "0.74%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 3618,
    "Actual Price(INR)/Quintal": 3597,
    "Percentage Difference": "-0.58%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 3604,
    "Actual Price(INR)/Quintal": 3678,
    "Percentage Difference": "2.01%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 3707,
    "Actual Price(INR)/Quintal": 3668,
    "Percentage Difference": "-1.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 3605,
    "Actual Price(INR)/Quintal": 3625,
    "Percentage Difference": "0.55%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 3603,
    "Actual Price(INR)/Quintal": 3650,
    "Percentage Difference": "1.29%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 3611,
    "Actual Price(INR)/Quintal": 3612,
    "Percentage Difference": "0.03%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 3568,
    "Actual Price(INR)/Quintal": 3567,
    "Percentage Difference": "-0.03%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 3618,
    "Actual Price(INR)/Quintal": 3512,
    "Percentage Difference": "-3.02%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 3510,
    "Actual Price(INR)/Quintal": 3489,
    "Percentage Difference": "-0.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 3513,
    "Actual Price(INR)/Quintal": 3501,
    "Percentage Difference": "-0.34%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 3406,
    "Actual Price(INR)/Quintal": 3507,
    "Percentage Difference": "2.88%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 3409,
    "Actual Price(INR)/Quintal": 3475,
    "Percentage Difference": "1.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 3389,
    "Actual Price(INR)/Quintal": 3533,
    "Percentage Difference": "4.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 3611,
    "Actual Price(INR)/Quintal": 3665,
    "Percentage Difference": "1.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 3586,
    "Actual Price(INR)/Quintal": 3546,
    "Percentage Difference": "-1.13%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 3568,
    "Actual Price(INR)/Quintal": 3611,
    "Percentage Difference": "1.19%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 3581,
    "Actual Price(INR)/Quintal": 3612,
    "Percentage Difference": "0.86%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 3590,
    "Actual Price(INR)/Quintal": 3641,
    "Percentage Difference": "1.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 3707,
    "Actual Price(INR)/Quintal": 3809,
    "Percentage Difference": "2.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 3908,
    "Actual Price(INR)/Quintal": 3964,
    "Percentage Difference": "1.41%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 3915,
    "Actual Price(INR)/Quintal": 3851,
    "Percentage Difference": "-1.66%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 3811,
    "Actual Price(INR)/Quintal": 3605,
    "Percentage Difference": "-5.71%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 3775,
    "Actual Price(INR)/Quintal": 3625,
    "Percentage Difference": "-4.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 3619,
    "Actual Price(INR)/Quintal": 3504,
    "Percentage Difference": "-3.28%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 3393,
    "Actual Price(INR)/Quintal": 3228,
    "Percentage Difference": "-5.11%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 3399,
    "Actual Price(INR)/Quintal": 3261,
    "Percentage Difference": "-4.23%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 3455,
    "Actual Price(INR)/Quintal": 3410,
    "Percentage Difference": "-1.32%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 3360,
    "Actual Price(INR)/Quintal": 3402,
    "Percentage Difference": "1.23%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 3536,
    "Actual Price(INR)/Quintal": 3654,
    "Percentage Difference": "3.23%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 3678,
    "Actual Price(INR)/Quintal": 3821,
    "Percentage Difference": "3.74%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 3738,
    "Actual Price(INR)/Quintal": 3824,
    "Percentage Difference": "2.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 3628,
    "Actual Price(INR)/Quintal": 3745,
    "Percentage Difference": "3.12%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 2898,
    "Actual Price(INR)/Quintal": 2800,
    "Percentage Difference": "-3.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 2824,
    "Actual Price(INR)/Quintal": 2844,
    "Percentage Difference": "0.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 2833,
    "Actual Price(INR)/Quintal": 2841,
    "Percentage Difference": "0.28%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 2819,
    "Actual Price(INR)/Quintal": 3028,
    "Percentage Difference": "6.90%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 3065,
    "Actual Price(INR)/Quintal": 3142,
    "Percentage Difference": "2.45%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 3184,
    "Actual Price(INR)/Quintal": 3199,
    "Percentage Difference": "0.47%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 3204,
    "Actual Price(INR)/Quintal": 3217,
    "Percentage Difference": "0.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 3252,
    "Actual Price(INR)/Quintal": 3241,
    "Percentage Difference": "-0.34%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 3241,
    "Actual Price(INR)/Quintal": 3262,
    "Percentage Difference": "0.64%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 3272,
    "Actual Price(INR)/Quintal": 3408,
    "Percentage Difference": "3.99%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 3420,
    "Actual Price(INR)/Quintal": 3372,
    "Percentage Difference": "-1.42%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 3361,
    "Actual Price(INR)/Quintal": 3350,
    "Percentage Difference": "-0.33%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 3300,
    "Actual Price(INR)/Quintal": 3399,
    "Percentage Difference": "2.91%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 3312,
    "Actual Price(INR)/Quintal": 3252,
    "Percentage Difference": "-1.85%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 3227,
    "Actual Price(INR)/Quintal": 3302,
    "Percentage Difference": "2.27%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 3293,
    "Actual Price(INR)/Quintal": 3259,
    "Percentage Difference": "-1.04%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 3240,
    "Actual Price(INR)/Quintal": 3319,
    "Percentage Difference": "2.38%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 3239,
    "Actual Price(INR)/Quintal": 3256,
    "Percentage Difference": "0.52%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 3222,
    "Actual Price(INR)/Quintal": 3234,
    "Percentage Difference": "0.37%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 3231,
    "Actual Price(INR)/Quintal": 3328,
    "Percentage Difference": "2.91%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 3333,
    "Actual Price(INR)/Quintal": 3256,
    "Percentage Difference": "-2.36%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 3213,
    "Actual Price(INR)/Quintal": 3231,
    "Percentage Difference": "0.56%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 3204,
    "Actual Price(INR)/Quintal": 3165,
    "Percentage Difference": "-1.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 3167,
    "Actual Price(INR)/Quintal": 3177,
    "Percentage Difference": "0.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 3196,
    "Actual Price(INR)/Quintal": 3296,
    "Percentage Difference": "3.03%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 3294,
    "Actual Price(INR)/Quintal": 3172,
    "Percentage Difference": "-3.85%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 3193,
    "Actual Price(INR)/Quintal": 3296,
    "Percentage Difference": "3.13%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 3302,
    "Actual Price(INR)/Quintal": 3431,
    "Percentage Difference": "3.76%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 3305,
    "Actual Price(INR)/Quintal": 3453,
    "Percentage Difference": "4.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 3400,
    "Actual Price(INR)/Quintal": 3441,
    "Percentage Difference": "1.19%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 3408,
    "Actual Price(INR)/Quintal": 3454,
    "Percentage Difference": "1.33%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 3449,
    "Actual Price(INR)/Quintal": 3459,
    "Percentage Difference": "0.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 3461,
    "Actual Price(INR)/Quintal": 3587,
    "Percentage Difference": "3.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 3523,
    "Actual Price(INR)/Quintal": 3744,
    "Percentage Difference": "5.90%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 3778,
    "Actual Price(INR)/Quintal": 3920,
    "Percentage Difference": "3.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 3923,
    "Actual Price(INR)/Quintal": 3798,
    "Percentage Difference": "-3.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 3759,
    "Actual Price(INR)/Quintal": 3678,
    "Percentage Difference": "-2.20%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 3638,
    "Actual Price(INR)/Quintal": 3661,
    "Percentage Difference": "0.63%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 3669,
    "Actual Price(INR)/Quintal": 3665,
    "Percentage Difference": "-0.11%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 3640,
    "Actual Price(INR)/Quintal": 3635,
    "Percentage Difference": "-0.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 3639,
    "Actual Price(INR)/Quintal": 3646,
    "Percentage Difference": "0.19%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 3646,
    "Actual Price(INR)/Quintal": 3651,
    "Percentage Difference": "0.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 3608,
    "Actual Price(INR)/Quintal": 3567,
    "Percentage Difference": "-1.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 3573,
    "Actual Price(INR)/Quintal": 3726,
    "Percentage Difference": "4.11%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 3621,
    "Actual Price(INR)/Quintal": 3693,
    "Percentage Difference": "1.95%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 3637,
    "Actual Price(INR)/Quintal": 3612,
    "Percentage Difference": "-0.69%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 3638,
    "Actual Price(INR)/Quintal": 3706,
    "Percentage Difference": "1.83%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 3702,
    "Actual Price(INR)/Quintal": 3651,
    "Percentage Difference": "-1.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 3217,
    "Actual Price(INR)/Quintal": 3170,
    "Percentage Difference": "-1.48%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 3178,
    "Actual Price(INR)/Quintal": 3103,
    "Percentage Difference": "-2.47%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 3161,
    "Actual Price(INR)/Quintal": 3192,
    "Percentage Difference": "0.97%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 3205,
    "Actual Price(INR)/Quintal": 3213,
    "Percentage Difference": "0.24%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 3204,
    "Actual Price(INR)/Quintal": 3223,
    "Percentage Difference": "0.59%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 3202,
    "Actual Price(INR)/Quintal": 3373,
    "Percentage Difference": "5.07%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 3429,
    "Actual Price(INR)/Quintal": 3339,
    "Percentage Difference": "-2.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 3354,
    "Actual Price(INR)/Quintal": 3582,
    "Percentage Difference": "6.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 3657,
    "Actual Price(INR)/Quintal": 3342,
    "Percentage Difference": "-9.45%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 3232,
    "Actual Price(INR)/Quintal": 3345,
    "Percentage Difference": "3.38%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 3230,
    "Actual Price(INR)/Quintal": 3201,
    "Percentage Difference": "-0.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 3218,
    "Actual Price(INR)/Quintal": 3219,
    "Percentage Difference": "0.03%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 3218,
    "Actual Price(INR)/Quintal": 3300,
    "Percentage Difference": "2.48%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 3224,
    "Actual Price(INR)/Quintal": 2966,
    "Percentage Difference": "-8.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 3038,
    "Actual Price(INR)/Quintal": 2998,
    "Percentage Difference": "-1.33%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 2949,
    "Actual Price(INR)/Quintal": 3045,
    "Percentage Difference": "3.15%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 2950,
    "Actual Price(INR)/Quintal": 2889,
    "Percentage Difference": "-2.11%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 2894,
    "Actual Price(INR)/Quintal": 2950,
    "Percentage Difference": "1.90%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 2971,
    "Actual Price(INR)/Quintal": 3060,
    "Percentage Difference": "2.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 3081,
    "Actual Price(INR)/Quintal": 2890,
    "Percentage Difference": "-6.61%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 2863,
    "Actual Price(INR)/Quintal": 2939,
    "Percentage Difference": "2.59%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 2867,
    "Actual Price(INR)/Quintal": 2869,
    "Percentage Difference": "0.07%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 2891,
    "Actual Price(INR)/Quintal": 2900,
    "Percentage Difference": "0.31%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 2855,
    "Actual Price(INR)/Quintal": 2832,
    "Percentage Difference": "-0.81%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 2887,
    "Actual Price(INR)/Quintal": 2825,
    "Percentage Difference": "-2.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 2792,
    "Actual Price(INR)/Quintal": 2799,
    "Percentage Difference": "0.25%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 2770,
    "Actual Price(INR)/Quintal": 2911,
    "Percentage Difference": "4.84%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 2964,
    "Actual Price(INR)/Quintal": 3050,
    "Percentage Difference": "2.82%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 3107,
    "Actual Price(INR)/Quintal": 3174,
    "Percentage Difference": "2.11%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 3109,
    "Actual Price(INR)/Quintal": 3148,
    "Percentage Difference": "1.24%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 3107,
    "Actual Price(INR)/Quintal": 3052,
    "Percentage Difference": "-1.80%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 3055,
    "Actual Price(INR)/Quintal": 3040,
    "Percentage Difference": "-0.49%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 3009,
    "Actual Price(INR)/Quintal": 3090,
    "Percentage Difference": "2.62%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 3007,
    "Actual Price(INR)/Quintal": 3051,
    "Percentage Difference": "1.44%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 2924,
    "Actual Price(INR)/Quintal": 2995,
    "Percentage Difference": "1.44%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 2989,
    "Actual Price(INR)/Quintal": 2949,
    "Percentage Difference": "-1.36%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 2978,
    "Actual Price(INR)/Quintal": 3016,
    "Percentage Difference": "1.26%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 2938,
    "Actual Price(INR)/Quintal": 2968,
    "Percentage Difference": "1.01%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 2923,
    "Actual Price(INR)/Quintal": 2943,
    "Percentage Difference": "0.68%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 2927,
    "Actual Price(INR)/Quintal": 2790,
    "Percentage Difference": "-4.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 2802,
    "Actual Price(INR)/Quintal": 2695,
    "Percentage Difference": "-3.97%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 2724,
    "Actual Price(INR)/Quintal": 2692,
    "Percentage Difference": "-1.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 2738,
    "Actual Price(INR)/Quintal": 2711,
    "Percentage Difference": "-1.00%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 2782,
    "Actual Price(INR)/Quintal": 2831,
    "Percentage Difference": "1.73%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 2780,
    "Actual Price(INR)/Quintal": 2835,
    "Percentage Difference": "1.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 2819,
    "Actual Price(INR)/Quintal": 2784,
    "Percentage Difference": "-1.26%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 2802,
    "Actual Price(INR)/Quintal": 2902,
    "Percentage Difference": "3.45%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 2879,
    "Actual Price(INR)/Quintal": 2892,
    "Percentage Difference": "0.45%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 3394,
    "Actual Price(INR)/Quintal": 3493,
    "Percentage Difference": "2.83%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 3378,
    "Actual Price(INR)/Quintal": 3514,
    "Percentage Difference": "3.87%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 3353,
    "Actual Price(INR)/Quintal": 3504,
    "Percentage Difference": "4.31%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 3373,
    "Actual Price(INR)/Quintal": 3451,
    "Percentage Difference": "2.26%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 3324,
    "Actual Price(INR)/Quintal": 3485,
    "Percentage Difference": "4.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 3343,
    "Actual Price(INR)/Quintal": 3580,
    "Percentage Difference": "6.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 3382,
    "Actual Price(INR)/Quintal": 3547,
    "Percentage Difference": "4.65%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 3376,
    "Actual Price(INR)/Quintal": 3609,
    "Percentage Difference": "6.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 3440,
    "Actual Price(INR)/Quintal": 3512,
    "Percentage Difference": "2.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 3485,
    "Actual Price(INR)/Quintal": 3555,
    "Percentage Difference": "1.97%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 3566,
    "Actual Price(INR)/Quintal": 3437,
    "Percentage Difference": "-3.57%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 3571,
    "Actual Price(INR)/Quintal": 3471,
    "Percentage Difference": "-2.88%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 3467,
    "Actual Price(INR)/Quintal": 3420,
    "Percentage Difference": "-1.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 3454,
    "Actual Price(INR)/Quintal": 3468,
    "Percentage Difference": "0.40%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 3502,
    "Actual Price(INR)/Quintal": 3427,
    "Percentage Difference": "-2.83%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 3511,
    "Actual Price(INR)/Quintal": 3501,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 3524,
    "Actual Price(INR)/Quintal": 3554,
    "Percentage Difference": "0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 3529,
    "Actual Price(INR)/Quintal": 3471,
    "Percentage Difference": "-1.67%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 3484,
    "Actual Price(INR)/Quintal": 3330,
    "Percentage Difference": "-4.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 3332,
    "Actual Price(INR)/Quintal": 3199,
    "Percentage Difference": "-4.16%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 3149,
    "Actual Price(INR)/Quintal": 3213,
    "Percentage Difference": "1.99%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 3188,
    "Actual Price(INR)/Quintal": 3163,
    "Percentage Difference": "-0.79%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 3154,
    "Actual Price(INR)/Quintal": 3379,
    "Percentage Difference": "6.66%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 3339,
    "Actual Price(INR)/Quintal": 3252,
    "Percentage Difference": "-2.67%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 3276,
    "Actual Price(INR)/Quintal": 3274,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 3285,
    "Actual Price(INR)/Quintal": 3387,
    "Percentage Difference": "3.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 3324,
    "Actual Price(INR)/Quintal": 3306,
    "Percentage Difference": "-0.54%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 3327,
    "Actual Price(INR)/Quintal": 3404,
    "Percentage Difference": "2.26%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 3407,
    "Actual Price(INR)/Quintal": 3474,
    "Percentage Difference": "1.93%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 3409,
    "Actual Price(INR)/Quintal": 3421,
    "Percentage Difference": "0.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 3378,
    "Actual Price(INR)/Quintal": 3578,
    "Percentage Difference": "5.59%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 3383,
    "Actual Price(INR)/Quintal": 3528,
    "Percentage Difference": "4.11%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 3451,
    "Actual Price(INR)/Quintal": 3465,
    "Percentage Difference": "0.40%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 3467,
    "Actual Price(INR)/Quintal": 3478,
    "Percentage Difference": "0.32%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 3423,
    "Actual Price(INR)/Quintal": 3359,
    "Percentage Difference": "-1.91%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 3375,
    "Actual Price(INR)/Quintal": 3391,
    "Percentage Difference": "0.47%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 3353,
    "Actual Price(INR)/Quintal": 3198,
    "Percentage Difference": "-4.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 3187,
    "Actual Price(INR)/Quintal": 3225,
    "Percentage Difference": "1.18%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 3271,
    "Actual Price(INR)/Quintal": 3224,
    "Percentage Difference": "-1.46%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 3217,
    "Actual Price(INR)/Quintal": 3422,
    "Percentage Difference": "5.99%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 3302,
    "Actual Price(INR)/Quintal": 3240,
    "Percentage Difference": "-1.91%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 3268,
    "Actual Price(INR)/Quintal": 3138,
    "Percentage Difference": "-4.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 3115,
    "Actual Price(INR)/Quintal": 3145,
    "Percentage Difference": "0.96%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 3172,
    "Actual Price(INR)/Quintal": 3093,
    "Percentage Difference": "-2.55%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 3087,
    "Actual Price(INR)/Quintal": 3143,
    "Percentage Difference": "1.78%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 3166,
    "Actual Price(INR)/Quintal": 3162,
    "Percentage Difference": "-0.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 3256,
    "Actual Price(INR)/Quintal": 3298,
    "Percentage Difference": "1.27%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 3256,
    "Actual Price(INR)/Quintal": 3316,
    "Percentage Difference": "1.81%",
    "YEAR": 2016
  }
];
