export const Potato = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1741,"Actual Price(INR)/Quintal":1848,"Percentage Difference":"5.79%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":1770,"Actual Price(INR)/Quintal":1856,"Percentage Difference":"4.63%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1801,"Actual Price(INR)/Quintal":1747,"Percentage Difference":"-3.09%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1665,"Actual Price(INR)/Quintal":1764,"Percentage Difference":"5.61%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1658,"Actual Price(INR)/Quintal":1691,"Percentage Difference":"1.95%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1676,"Actual Price(INR)/Quintal":1725,"Percentage Difference":"2.84%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1712,"Actual Price(INR)/Quintal":1720,"Percentage Difference":"0.47%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1784,"Actual Price(INR)/Quintal":1850,"Percentage Difference":"3.57%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1705,"Actual Price(INR)/Quintal":1764,"Percentage Difference":"3.34%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1720,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"4.87%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1805,"Actual Price(INR)/Quintal":1806,"Percentage Difference":"0.06%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":1819,"Actual Price(INR)/Quintal":1860,"Percentage Difference":"2.20%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":2277,"Actual Price(INR)/Quintal":2221,"Percentage Difference":"-2.52%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":2328,"Actual Price(INR)/Quintal":2390,"Percentage Difference":"2.59%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":2323,"Actual Price(INR)/Quintal":2380,"Percentage Difference":"2.39%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":2320,"Actual Price(INR)/Quintal":2494,"Percentage Difference":"6.98%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":2344,"Actual Price(INR)/Quintal":2400,"Percentage Difference":"2.33%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":2313,"Actual Price(INR)/Quintal":2398,"Percentage Difference":"3.54%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":2425,"Actual Price(INR)/Quintal":2512,"Percentage Difference":"3.46%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":2474,"Actual Price(INR)/Quintal":2524,"Percentage Difference":"1.98%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":2472,"Actual Price(INR)/Quintal":2412,"Percentage Difference":"-2.49%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":2461,"Actual Price(INR)/Quintal":2585,"Percentage Difference":"4.80%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2619,"Actual Price(INR)/Quintal":2700,"Percentage Difference":"3.00%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2646,"Actual Price(INR)/Quintal":2645,"Percentage Difference":"-0.04%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2655,"Actual Price(INR)/Quintal":2756,"Percentage Difference":"3.66%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2667,"Actual Price(INR)/Quintal":2896,"Percentage Difference":"7.91%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2674,"Actual Price(INR)/Quintal":2827,"Percentage Difference":"5.41%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2958,"Actual Price(INR)/Quintal":3055,"Percentage Difference":"3.18%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2930,"Actual Price(INR)/Quintal":2945,"Percentage Difference":"0.51%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2956,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2838,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2848,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":2206,"Actual Price(INR)/Quintal":2276,"Percentage Difference":"3.08%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":2215,"Actual Price(INR)/Quintal":2300,"Percentage Difference":"3.70%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":2203,"Actual Price(INR)/Quintal":2253,"Percentage Difference":"2.22%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":2133,"Actual Price(INR)/Quintal":2154,"Percentage Difference":"0.97%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1880,"Actual Price(INR)/Quintal":1885,"Percentage Difference":"0.27%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1760,"Actual Price(INR)/Quintal":1700,"Percentage Difference":"-3.53%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1727,"Actual Price(INR)/Quintal":1590,"Percentage Difference":"-8.62%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1407,"Actual Price(INR)/Quintal":1296,"Percentage Difference":"-8.56%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1214,"Actual Price(INR)/Quintal":1166,"Percentage Difference":"-4.12%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1112,"Actual Price(INR)/Quintal":1119,"Percentage Difference":"0.63%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1126,"Actual Price(INR)/Quintal":1168,"Percentage Difference":"3.60%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":1222,"Actual Price(INR)/Quintal":1194,"Percentage Difference":"-2.35%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":1213,"Actual Price(INR)/Quintal":1164,"Percentage Difference":"-4.21%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1214,"Actual Price(INR)/Quintal":1216,"Percentage Difference":"0.16%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":1318,"Actual Price(INR)/Quintal":1338,"Percentage Difference":"1.49%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1422,"Actual Price(INR)/Quintal":1435,"Percentage Difference":"0.91%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1450,"Actual Price(INR)/Quintal":1494,"Percentage Difference":"2.95%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1668,"Actual Price(INR)/Quintal":1675,"Percentage Difference":"0.42%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1547,"Actual Price(INR)/Quintal":1600,"Percentage Difference":"3.31%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1604,"Actual Price(INR)/Quintal":1533,"Percentage Difference":"-4.63%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1532,"Actual Price(INR)/Quintal":1546,"Percentage Difference":"0.91%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1536,"Actual Price(INR)/Quintal":1573,"Percentage Difference":"2.35%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1556,"Actual Price(INR)/Quintal":1531,"Percentage Difference":"-1.63%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1546,"Actual Price(INR)/Quintal":1609,"Percentage Difference":"3.92%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1654,"Actual Price(INR)/Quintal":1702,"Percentage Difference":"2.82%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1648,"Actual Price(INR)/Quintal":1699,"Percentage Difference":"3.00%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1654,"Actual Price(INR)/Quintal":1584,"Percentage Difference":"-4.42%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1587,"Actual Price(INR)/Quintal":1631,"Percentage Difference":"2.70%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1527,"Actual Price(INR)/Quintal":1627,"Percentage Difference":"6.15%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1617,"Actual Price(INR)/Quintal":1688,"Percentage Difference":"4.21%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1599,"Actual Price(INR)/Quintal":1673,"Percentage Difference":"4.42%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1648,"Actual Price(INR)/Quintal":1704,"Percentage Difference":"3.29%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1818,"Actual Price(INR)/Quintal":1785,"Percentage Difference":"-1.85%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1738,"Actual Price(INR)/Quintal":1663,"Percentage Difference":"-4.51%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1690,"Actual Price(INR)/Quintal":1786,"Percentage Difference":"5.38%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1769,"Actual Price(INR)/Quintal":1748,"Percentage Difference":"-1.20%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1701,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"5.92%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1812,"Actual Price(INR)/Quintal":1741,"Percentage Difference":"-4.08%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1733,"Actual Price(INR)/Quintal":1729,"Percentage Difference":"-0.23%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1712,"Actual Price(INR)/Quintal":1719,"Percentage Difference":"0.41%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1713,"Actual Price(INR)/Quintal":1696,"Percentage Difference":"-1.00%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1592,"Actual Price(INR)/Quintal":1699,"Percentage Difference":"6.30%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1646,"Actual Price(INR)/Quintal":1708,"Percentage Difference":"3.63%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1837,"Actual Price(INR)/Quintal":1888,"Percentage Difference":"2.70%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":1851,"Actual Price(INR)/Quintal":1868,"Percentage Difference":"0.91%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1858,"Actual Price(INR)/Quintal":1828,"Percentage Difference":"-1.64%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1812,"Actual Price(INR)/Quintal":1859,"Percentage Difference":"2.53%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1781,"Actual Price(INR)/Quintal":1750,"Percentage Difference":"-1.77%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":1583,"Actual Price(INR)/Quintal":1650,"Percentage Difference":"4.06%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":1668,"Actual Price(INR)/Quintal":1638,"Percentage Difference":"-1.83%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":1640,"Actual Price(INR)/Quintal":1714,"Percentage Difference":"4.32%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":1605,"Actual Price(INR)/Quintal":1573,"Percentage Difference":"-2.03%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":1409,"Actual Price(INR)/Quintal":1431,"Percentage Difference":"1.54%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":1606,"Actual Price(INR)/Quintal":1639,"Percentage Difference":"2.01%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":1615,"Actual Price(INR)/Quintal":1594,"Percentage Difference":"-1.32%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":1602,"Actual Price(INR)/Quintal":1587,"Percentage Difference":"-0.95%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":1631,"Actual Price(INR)/Quintal":1688,"Percentage Difference":"3.38%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":1465,"Actual Price(INR)/Quintal":1488,"Percentage Difference":"1.55%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":1533,"Actual Price(INR)/Quintal":1565,"Percentage Difference":"2.04%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":1545,"Actual Price(INR)/Quintal":1521,"Percentage Difference":"-1.58%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":1553,"Actual Price(INR)/Quintal":1502,"Percentage Difference":"-3.40%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":1435,"Actual Price(INR)/Quintal":1511,"Percentage Difference":"5.03%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":1637,"Actual Price(INR)/Quintal":1665,"Percentage Difference":"1.68%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":1652,"Actual Price(INR)/Quintal":1669,"Percentage Difference":"1.02%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1644,"Actual Price(INR)/Quintal":1732,"Percentage Difference":"5.08%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1769,"Actual Price(INR)/Quintal":1840,"Percentage Difference":"3.86%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1889,"Actual Price(INR)/Quintal":1947,"Percentage Difference":"2.98%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1999,"Actual Price(INR)/Quintal":1998,"Percentage Difference":"-0.05%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1981,"Actual Price(INR)/Quintal":2038,"Percentage Difference":"2.80%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1980,"Actual Price(INR)/Quintal":2014,"Percentage Difference":"1.69%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":2070,"Actual Price(INR)/Quintal":2126,"Percentage Difference":"2.63%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":2087,"Actual Price(INR)/Quintal":2080,"Percentage Difference":"-0.34%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":2033,"Actual Price(INR)/Quintal":1971,"Percentage Difference":"-3.15%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":2174,"Actual Price(INR)/Quintal":2275,"Percentage Difference":"4.44%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":2290,"Actual Price(INR)/Quintal":2366,"Percentage Difference":"3.21%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":2230,"Actual Price(INR)/Quintal":2118,"Percentage Difference":"-5.29%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":2076,"Actual Price(INR)/Quintal":2105,"Percentage Difference":"1.38%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":2109,"Actual Price(INR)/Quintal":2154,"Percentage Difference":"2.09%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":2144,"Actual Price(INR)/Quintal":2184,"Percentage Difference":"1.83%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":2157,"Actual Price(INR)/Quintal":2100,"Percentage Difference":"-2.71%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":2038,"Actual Price(INR)/Quintal":2105,"Percentage Difference":"3.18%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":2161,"Actual Price(INR)/Quintal":2260,"Percentage Difference":"4.38%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":2118,"Actual Price(INR)/Quintal":2112,"Percentage Difference":"-0.28%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":2164,"Actual Price(INR)/Quintal":2068,"Percentage Difference":"-4.64%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":2116,"Actual Price(INR)/Quintal":2121,"Percentage Difference":"0.24%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":2083,"Actual Price(INR)/Quintal":2099,"Percentage Difference":"0.76%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":2147,"Actual Price(INR)/Quintal":2280,"Percentage Difference":"5.83%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":2195,"Actual Price(INR)/Quintal":2300,"Percentage Difference":"4.57%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":2314,"Actual Price(INR)/Quintal":2260,"Percentage Difference":"-2.39%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":2268,"Actual Price(INR)/Quintal":2300,"Percentage Difference":"1.39%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":2288,"Actual Price(INR)/Quintal":2220,"Percentage Difference":"-3.06%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":2195,"Actual Price(INR)/Quintal":2193,"Percentage Difference":"-0.09%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":2172,"Actual Price(INR)/Quintal":2200,"Percentage Difference":"1.27%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":2139,"Actual Price(INR)/Quintal":2205,"Percentage Difference":"2.99%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":2113,"Actual Price(INR)/Quintal":2211,"Percentage Difference":"4.43%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":2013,"Actual Price(INR)/Quintal":2103,"Percentage Difference":"4.28%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":2145,"Actual Price(INR)/Quintal":2189,"Percentage Difference":"2.01%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":1893,"Actual Price(INR)/Quintal":1896,"Percentage Difference":"0.16%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":1730,"Actual Price(INR)/Quintal":1746,"Percentage Difference":"0.92%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":1758,"Actual Price(INR)/Quintal":1792,"Percentage Difference":"1.90%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1609,"Actual Price(INR)/Quintal":1557,"Percentage Difference":"-3.34%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1505,"Actual Price(INR)/Quintal":1490,"Percentage Difference":"-1.01%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1378,"Actual Price(INR)/Quintal":1410,"Percentage Difference":"2.27%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1384,"Actual Price(INR)/Quintal":1350,"Percentage Difference":"-2.52%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1327,"Actual Price(INR)/Quintal":1294,"Percentage Difference":"-2.55%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1305,"Actual Price(INR)/Quintal":1271,"Percentage Difference":"-2.68%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1186,"Actual Price(INR)/Quintal":1173,"Percentage Difference":"-1.11%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1172,"Actual Price(INR)/Quintal":1238,"Percentage Difference":"5.33%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1316,"Actual Price(INR)/Quintal":1402,"Percentage Difference":"6.13%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1440,"Actual Price(INR)/Quintal":1451,"Percentage Difference":"0.76%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1405,"Actual Price(INR)/Quintal":1473,"Percentage Difference":"4.62%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1471,"Actual Price(INR)/Quintal":1466,"Percentage Difference":"-0.34%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":1482,"Actual Price(INR)/Quintal":1445,"Percentage Difference":"-2.56%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":1447,"Actual Price(INR)/Quintal":1467,"Percentage Difference":"1.36%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":1434,"Actual Price(INR)/Quintal":1392,"Percentage Difference":"-3.02%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":1424,"Actual Price(INR)/Quintal":1411,"Percentage Difference":"-0.92%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":1450,"Actual Price(INR)/Quintal":1470,"Percentage Difference":"1.36%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":1477,"Actual Price(INR)/Quintal":1461,"Percentage Difference":"-1.10%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":1510,"Actual Price(INR)/Quintal":1506,"Percentage Difference":"-0.27%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":1516,"Actual Price(INR)/Quintal":1500,"Percentage Difference":"-1.07%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":1410,"Actual Price(INR)/Quintal":1389,"Percentage Difference":"-1.51%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":1332,"Actual Price(INR)/Quintal":1322,"Percentage Difference":"-0.76%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":1333,"Actual Price(INR)/Quintal":1380,"Percentage Difference":"3.41%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":1346,"Actual Price(INR)/Quintal":1400,"Percentage Difference":"3.86%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":1381,"Actual Price(INR)/Quintal":1403,"Percentage Difference":"1.57%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":1350,"Actual Price(INR)/Quintal":1355,"Percentage Difference":"0.37%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":1364,"Actual Price(INR)/Quintal":1402,"Percentage Difference":"2.71%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":1261,"Actual Price(INR)/Quintal":1271,"Percentage Difference":"0.79%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":1293,"Actual Price(INR)/Quintal":1321,"Percentage Difference":"2.12%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":1295,"Actual Price(INR)/Quintal":1308,"Percentage Difference":"0.99%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":1249,"Actual Price(INR)/Quintal":1222,"Percentage Difference":"-2.21%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":1202,"Actual Price(INR)/Quintal":1255,"Percentage Difference":"4.22%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":1224,"Actual Price(INR)/Quintal":1280,"Percentage Difference":"4.38%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":1296,"Actual Price(INR)/Quintal":1261,"Percentage Difference":"-2.78%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":1311,"Actual Price(INR)/Quintal":1377,"Percentage Difference":"4.79%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":1339,"Actual Price(INR)/Quintal":1347,"Percentage Difference":"0.59%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":1316,"Actual Price(INR)/Quintal":1365,"Percentage Difference":"3.59%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":1302,"Actual Price(INR)/Quintal":1268,"Percentage Difference":"-2.68%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":1308,"Actual Price(INR)/Quintal":1610,"Percentage Difference":"18.76%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":1707,"Actual Price(INR)/Quintal":1825,"Percentage Difference":"6.47%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":1495,"Actual Price(INR)/Quintal":1412,"Percentage Difference":"-5.88%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":1628,"Actual Price(INR)/Quintal":1717,"Percentage Difference":"5.18%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":1643,"Actual Price(INR)/Quintal":1611,"Percentage Difference":"-1.99%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":1633,"Actual Price(INR)/Quintal":1585,"Percentage Difference":"-3.03%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":2155,"Actual Price(INR)/Quintal":2116,"Percentage Difference":"-1.84%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":2279,"Actual Price(INR)/Quintal":2290,"Percentage Difference":"0.48%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":2266,"Actual Price(INR)/Quintal":2293,"Percentage Difference":"1.18%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":2248,"Actual Price(INR)/Quintal":2016,"Percentage Difference":"-11.51%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":1893,"Actual Price(INR)/Quintal":1919,"Percentage Difference":"1.35%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":1666,"Actual Price(INR)/Quintal":1601,"Percentage Difference":"-4.06%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":1576,"Actual Price(INR)/Quintal":1487,"Percentage Difference":"-5.99%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":1488,"Actual Price(INR)/Quintal":1498,"Percentage Difference":"0.67%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":1432,"Actual Price(INR)/Quintal":1441,"Percentage Difference":"0.62%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":1407,"Actual Price(INR)/Quintal":1450,"Percentage Difference":"2.97%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":1446,"Actual Price(INR)/Quintal":1520,"Percentage Difference":"4.87%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":1536,"Actual Price(INR)/Quintal":1562,"Percentage Difference":"1.66%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":1486,"Actual Price(INR)/Quintal":1708,"Percentage Difference":"13.00%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":1665,"Actual Price(INR)/Quintal":1808,"Percentage Difference":"7.91%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":1916,"Actual Price(INR)/Quintal":1921,"Percentage Difference":"0.26%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":1908,"Actual Price(INR)/Quintal":1922,"Percentage Difference":"0.73%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":1826,"Actual Price(INR)/Quintal":1883,"Percentage Difference":"3.03%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":1820,"Actual Price(INR)/Quintal":1830,"Percentage Difference":"0.55%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":1833,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"3.53%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":1841,"Actual Price(INR)/Quintal":1892,"Percentage Difference":"2.70%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":1881,"Actual Price(INR)/Quintal":1883,"Percentage Difference":"0.11%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":1846,"Actual Price(INR)/Quintal":1900,"Percentage Difference":"2.84%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":1871,"Actual Price(INR)/Quintal":1921,"Percentage Difference":"2.60%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":1937,"Actual Price(INR)/Quintal":1985,"Percentage Difference":"2.42%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":1942,"Actual Price(INR)/Quintal":2014,"Percentage Difference":"3.57%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":1913,"Actual Price(INR)/Quintal":2054,"Percentage Difference":"6.86%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":2115,"Actual Price(INR)/Quintal":2220,"Percentage Difference":"4.73%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":2226,"Actual Price(INR)/Quintal":2291,"Percentage Difference":"2.84%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":2275,"Actual Price(INR)/Quintal":2300,"Percentage Difference":"1.09%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":2274,"Actual Price(INR)/Quintal":2280,"Percentage Difference":"0.26%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":2171,"Actual Price(INR)/Quintal":2231,"Percentage Difference":"2.69%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":2187,"Actual Price(INR)/Quintal":2176,"Percentage Difference":"-0.51%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":1982,"Actual Price(INR)/Quintal":2050,"Percentage Difference":"3.32%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":1999,"Actual Price(INR)/Quintal":2047,"Percentage Difference":"2.34%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":2088,"Actual Price(INR)/Quintal":2169,"Percentage Difference":"3.73%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":2262,"Actual Price(INR)/Quintal":2308,"Percentage Difference":"1.99%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":2346,"Actual Price(INR)/Quintal":2411,"Percentage Difference":"2.70%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":2373,"Actual Price(INR)/Quintal":2350,"Percentage Difference":"-0.98%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":2175,"Actual Price(INR)/Quintal":2223,"Percentage Difference":"2.16%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":2917,"Actual Price(INR)/Quintal":3061,"Percentage Difference":"4.70%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":3173,"Actual Price(INR)/Quintal":3105,"Percentage Difference":"-2.19%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":3134,"Actual Price(INR)/Quintal":3051,"Percentage Difference":"-2.72%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":3062,"Actual Price(INR)/Quintal":3222,"Percentage Difference":"4.97%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":3206,"Actual Price(INR)/Quintal":3275,"Percentage Difference":"2.11%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":3208,"Actual Price(INR)/Quintal":3357,"Percentage Difference":"4.44%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":3032,"Actual Price(INR)/Quintal":2950,"Percentage Difference":"-2.78%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":2904,"Actual Price(INR)/Quintal":2847,"Percentage Difference":"-2.00%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":2510,"Actual Price(INR)/Quintal":2441,"Percentage Difference":"-2.83%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1178,
    "Actual Price(INR)/Quintal": 1185,
    "Percentage Difference": "0.59%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1179,
    "Actual Price(INR)/Quintal": 1223,
    "Percentage Difference": "3.60%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1166,
    "Actual Price(INR)/Quintal": 1147,
    "Percentage Difference": "-1.66%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1169,
    "Actual Price(INR)/Quintal": 1206,
    "Percentage Difference": "3.07%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1210,
    "Actual Price(INR)/Quintal": 1267,
    "Percentage Difference": "4.50%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1235,
    "Actual Price(INR)/Quintal": 1242,
    "Percentage Difference": "0.56%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1218,
    "Actual Price(INR)/Quintal": 1153,
    "Percentage Difference": "-5.64%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1132,
    "Actual Price(INR)/Quintal": 1122,
    "Percentage Difference": "-0.89%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1117,
    "Actual Price(INR)/Quintal": 1157,
    "Percentage Difference": "3.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1113,
    "Actual Price(INR)/Quintal": 1068,
    "Percentage Difference": "-4.21%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1078,
    "Actual Price(INR)/Quintal": 1037,
    "Percentage Difference": "-3.95%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1026,
    "Actual Price(INR)/Quintal": 1074,
    "Percentage Difference": "4.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1029,
    "Actual Price(INR)/Quintal": 1043,
    "Percentage Difference": "1.34%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 1038,
    "Actual Price(INR)/Quintal": 1074,
    "Percentage Difference": "3.35%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 1077,
    "Actual Price(INR)/Quintal": 1122,
    "Percentage Difference": "4.01%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1187,
    "Actual Price(INR)/Quintal": 1246,
    "Percentage Difference": "4.74%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 1299,
    "Actual Price(INR)/Quintal": 1285,
    "Percentage Difference": "-1.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 1291,
    "Actual Price(INR)/Quintal": 1303,
    "Percentage Difference": "0.92%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 1298,
    "Actual Price(INR)/Quintal": 1310,
    "Percentage Difference": "0.92%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 1283,
    "Actual Price(INR)/Quintal": 1302,
    "Percentage Difference": "1.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1347,
    "Actual Price(INR)/Quintal": 1409,
    "Percentage Difference": "4.40%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1398,
    "Actual Price(INR)/Quintal": 1401,
    "Percentage Difference": "0.21%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1361,
    "Actual Price(INR)/Quintal": 1406,
    "Percentage Difference": "3.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1385,
    "Actual Price(INR)/Quintal": 1405,
    "Percentage Difference": "1.42%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1379,
    "Actual Price(INR)/Quintal": 1381,
    "Percentage Difference": "0.14%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1395,
    "Actual Price(INR)/Quintal": 1360,
    "Percentage Difference": "-2.57%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1355,
    "Actual Price(INR)/Quintal": 1297,
    "Percentage Difference": "-4.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1229,
    "Actual Price(INR)/Quintal": 1207,
    "Percentage Difference": "-1.82%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1209,
    "Actual Price(INR)/Quintal": 1180,
    "Percentage Difference": "-2.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1101,
    "Actual Price(INR)/Quintal": 1138,
    "Percentage Difference": "3.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1107,
    "Actual Price(INR)/Quintal": 1110,
    "Percentage Difference": "0.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 1105,
    "Actual Price(INR)/Quintal": 1205,
    "Percentage Difference": "8.30%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 1248,
    "Actual Price(INR)/Quintal": 1250,
    "Percentage Difference": "0.16%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 1251,
    "Actual Price(INR)/Quintal": 1287,
    "Percentage Difference": "2.80%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 1228,
    "Actual Price(INR)/Quintal": 1245,
    "Percentage Difference": "1.37%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 1183,
    "Actual Price(INR)/Quintal": 1201,
    "Percentage Difference": "1.50%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 1220,
    "Actual Price(INR)/Quintal": 1256,
    "Percentage Difference": "2.87%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 1238,
    "Actual Price(INR)/Quintal": 1275,
    "Percentage Difference": "2.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 1218,
    "Actual Price(INR)/Quintal": 1276,
    "Percentage Difference": "4.55%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1220,
    "Actual Price(INR)/Quintal": 1300,
    "Percentage Difference": "6.15%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1462,
    "Actual Price(INR)/Quintal": 1550,
    "Percentage Difference": "5.68%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1542,
    "Actual Price(INR)/Quintal": 1515,
    "Percentage Difference": "-1.78%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1495,
    "Actual Price(INR)/Quintal": 1529,
    "Percentage Difference": "2.22%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1575,
    "Actual Price(INR)/Quintal": 1595,
    "Percentage Difference": "1.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1581,
    "Actual Price(INR)/Quintal": 1601,
    "Percentage Difference": "1.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1613,
    "Actual Price(INR)/Quintal": 1654,
    "Percentage Difference": "2.48%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1592,
    "Actual Price(INR)/Quintal": 1609,
    "Percentage Difference": "1.06%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1797,
    "Actual Price(INR)/Quintal": 1870,
    "Percentage Difference": "3.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 1128,
    "Actual Price(INR)/Quintal": 1157,
    "Percentage Difference": "2.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 1214,
    "Actual Price(INR)/Quintal": 1220,
    "Percentage Difference": "0.49%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 1207,
    "Actual Price(INR)/Quintal": 1203,
    "Percentage Difference": "-0.33%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 1202,
    "Actual Price(INR)/Quintal": 1196,
    "Percentage Difference": "-0.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 1189,
    "Actual Price(INR)/Quintal": 1190,
    "Percentage Difference": "0.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 1188,
    "Actual Price(INR)/Quintal": 1226,
    "Percentage Difference": "3.10%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 1201,
    "Actual Price(INR)/Quintal": 1144,
    "Percentage Difference": "-4.98%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 1129,
    "Actual Price(INR)/Quintal": 1142,
    "Percentage Difference": "1.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 1132,
    "Actual Price(INR)/Quintal": 1209,
    "Percentage Difference": "6.37%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 1234,
    "Actual Price(INR)/Quintal": 1255,
    "Percentage Difference": "1.67%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 1236,
    "Actual Price(INR)/Quintal": 1273,
    "Percentage Difference": "2.91%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 1279,
    "Actual Price(INR)/Quintal": 1317,
    "Percentage Difference": "2.89%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 1305,
    "Actual Price(INR)/Quintal": 1287,
    "Percentage Difference": "-1.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 1213,
    "Actual Price(INR)/Quintal": 1416,
    "Percentage Difference": "14.34%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 1609,
    "Actual Price(INR)/Quintal": 1629,
    "Percentage Difference": "1.23%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 1628,
    "Actual Price(INR)/Quintal": 1709,
    "Percentage Difference": "4.74%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 1769,
    "Actual Price(INR)/Quintal": 1841,
    "Percentage Difference": "3.91%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 1886,
    "Actual Price(INR)/Quintal": 1817,
    "Percentage Difference": "-3.80%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 1765,
    "Actual Price(INR)/Quintal": 1772,
    "Percentage Difference": "0.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 1747,
    "Actual Price(INR)/Quintal": 1856,
    "Percentage Difference": "5.87%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 1919,
    "Actual Price(INR)/Quintal": 1995,
    "Percentage Difference": "3.81%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 1911,
    "Actual Price(INR)/Quintal": 1915,
    "Percentage Difference": "0.21%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 1901,
    "Actual Price(INR)/Quintal": 1909,
    "Percentage Difference": "0.42%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 1907,
    "Actual Price(INR)/Quintal": 1915,
    "Percentage Difference": "0.42%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 1905,
    "Actual Price(INR)/Quintal": 1946,
    "Percentage Difference": "2.11%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 1915,
    "Actual Price(INR)/Quintal": 1791,
    "Percentage Difference": "-6.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 1725,
    "Actual Price(INR)/Quintal": 1751,
    "Percentage Difference": "2.11%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 1737,
    "Actual Price(INR)/Quintal": 1761,
    "Percentage Difference": "1.36%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 1664,
    "Actual Price(INR)/Quintal": 1704,
    "Percentage Difference": "2.35%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 1781,
    "Actual Price(INR)/Quintal": 1790,
    "Percentage Difference": "0.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 1765,
    "Actual Price(INR)/Quintal": 1818,
    "Percentage Difference": "2.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 1801,
    "Actual Price(INR)/Quintal": 1658,
    "Percentage Difference": "-8.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 1529,
    "Actual Price(INR)/Quintal": 1671,
    "Percentage Difference": "8.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 1599,
    "Actual Price(INR)/Quintal": 1593,
    "Percentage Difference": "-0.38%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 1610,
    "Actual Price(INR)/Quintal": 1671,
    "Percentage Difference": "3.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 1611,
    "Actual Price(INR)/Quintal": 1614,
    "Percentage Difference": "0.19%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 1658,
    "Actual Price(INR)/Quintal": 1679,
    "Percentage Difference": "1.25%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 1616,
    "Actual Price(INR)/Quintal": 1661,
    "Percentage Difference": "2.71%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 1560,
    "Actual Price(INR)/Quintal": 1642,
    "Percentage Difference": "4.99%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 1620,
    "Actual Price(INR)/Quintal": 1706,
    "Percentage Difference": "5.04%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 1726,
    "Actual Price(INR)/Quintal": 1728,
    "Percentage Difference": "0.12%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 1721,
    "Actual Price(INR)/Quintal": 1722,
    "Percentage Difference": "0.06%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 1725,
    "Actual Price(INR)/Quintal": 1638,
    "Percentage Difference": "-5.31%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 1538,
    "Actual Price(INR)/Quintal": 1540,
    "Percentage Difference": "0.13%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 1322,
    "Actual Price(INR)/Quintal": 1351,
    "Percentage Difference": "2.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 1226,
    "Actual Price(INR)/Quintal": 1171,
    "Percentage Difference": "-4.70%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 1127,
    "Actual Price(INR)/Quintal": 1204,
    "Percentage Difference": "6.40%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 1215,
    "Actual Price(INR)/Quintal": 1226,
    "Percentage Difference": "0.90%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1165,
    "Actual Price(INR)/Quintal": 1065,
    "Percentage Difference": "-9.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1037,
    "Actual Price(INR)/Quintal": 1027,
    "Percentage Difference": "-0.97%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1025,
    "Actual Price(INR)/Quintal": 945,
    "Percentage Difference": "-8.45%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 956,
    "Actual Price(INR)/Quintal": 892,
    "Percentage Difference": "-7.92%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 814,
    "Actual Price(INR)/Quintal": 880,
    "Percentage Difference": "7.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 887,
    "Actual Price(INR)/Quintal": 954,
    "Percentage Difference": "7.02%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 938,
    "Actual Price(INR)/Quintal": 939,
    "Percentage Difference": "0.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 940,
    "Actual Price(INR)/Quintal": 870,
    "Percentage Difference": "-8.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 878,
    "Actual Price(INR)/Quintal": 947,
    "Percentage Difference": "7.28%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 928,
    "Actual Price(INR)/Quintal": 890,
    "Percentage Difference": "-4.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 902,
    "Actual Price(INR)/Quintal": 940,
    "Percentage Difference": "4.04%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 912,
    "Actual Price(INR)/Quintal": 944,
    "Percentage Difference": "3.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 913,
    "Actual Price(INR)/Quintal": 1005,
    "Percentage Difference": "9.15%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1014,
    "Actual Price(INR)/Quintal": 1078,
    "Percentage Difference": "5.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1017,
    "Actual Price(INR)/Quintal": 1035,
    "Percentage Difference": "1.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1023,
    "Actual Price(INR)/Quintal": 1109,
    "Percentage Difference": "7.75%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1153,
    "Actual Price(INR)/Quintal": 1090,
    "Percentage Difference": "-5.78%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1052,
    "Actual Price(INR)/Quintal": 1089,
    "Percentage Difference": "3.40%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1042,
    "Actual Price(INR)/Quintal": 1056,
    "Percentage Difference": "1.33%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1037,
    "Actual Price(INR)/Quintal": 1044,
    "Percentage Difference": "0.67%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1023,
    "Actual Price(INR)/Quintal": 1072,
    "Percentage Difference": "4.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1034,
    "Actual Price(INR)/Quintal": 992,
    "Percentage Difference": "-4.23%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 991,
    "Actual Price(INR)/Quintal": 981,
    "Percentage Difference": "-1.02%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 993,
    "Actual Price(INR)/Quintal": 1046,
    "Percentage Difference": "5.07%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1028,
    "Actual Price(INR)/Quintal": 1037,
    "Percentage Difference": "0.87%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1048,
    "Actual Price(INR)/Quintal": 1050,
    "Percentage Difference": "0.19%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1058,
    "Actual Price(INR)/Quintal": 1081,
    "Percentage Difference": "2.13%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1059,
    "Actual Price(INR)/Quintal": 1115,
    "Percentage Difference": "5.02%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1129,
    "Actual Price(INR)/Quintal": 1229,
    "Percentage Difference": "8.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1236,
    "Actual Price(INR)/Quintal": 1243,
    "Percentage Difference": "0.56%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1230,
    "Actual Price(INR)/Quintal": 1206,
    "Percentage Difference": "-1.99%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1208,
    "Actual Price(INR)/Quintal": 1136,
    "Percentage Difference": "-6.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1145,
    "Actual Price(INR)/Quintal": 1252,
    "Percentage Difference": "8.55%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1304,
    "Actual Price(INR)/Quintal": 1444,
    "Percentage Difference": "9.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1401,
    "Actual Price(INR)/Quintal": 1294,
    "Percentage Difference": "-8.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1108,
    "Actual Price(INR)/Quintal": 1193,
    "Percentage Difference": "7.12%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1193,
    "Actual Price(INR)/Quintal": 1290,
    "Percentage Difference": "7.52%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1203,
    "Actual Price(INR)/Quintal": 1239,
    "Percentage Difference": "2.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1219,
    "Actual Price(INR)/Quintal": 1275,
    "Percentage Difference": "4.39%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1314,
    "Actual Price(INR)/Quintal": 1365,
    "Percentage Difference": "3.74%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 1403,
    "Actual Price(INR)/Quintal": 1214,
    "Percentage Difference": "-15.57%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 1185,
    "Actual Price(INR)/Quintal": 1201,
    "Percentage Difference": "1.33%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 1169,
    "Actual Price(INR)/Quintal": 1194,
    "Percentage Difference": "2.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 1223,
    "Actual Price(INR)/Quintal": 1195,
    "Percentage Difference": "-2.34%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1190,
    "Actual Price(INR)/Quintal": 1217,
    "Percentage Difference": "2.22%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1214,
    "Actual Price(INR)/Quintal": 1184,
    "Percentage Difference": "-2.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1231,
    "Actual Price(INR)/Quintal": 1194,
    "Percentage Difference": "-3.10%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1131,
    "Actual Price(INR)/Quintal": 1137,
    "Percentage Difference": "0.53%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 1207,
    "Actual Price(INR)/Quintal": 1194,
    "Percentage Difference": "-1.09%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 1141,
    "Actual Price(INR)/Quintal": 1114,
    "Percentage Difference": "-2.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 1023,
    "Actual Price(INR)/Quintal": 1084,
    "Percentage Difference": "5.63%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 1054,
    "Actual Price(INR)/Quintal": 1089,
    "Percentage Difference": "3.21%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 1084,
    "Actual Price(INR)/Quintal": 1032,
    "Percentage Difference": "-5.04%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 1163,
    "Actual Price(INR)/Quintal": 1104,
    "Percentage Difference": "-5.34%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 1236,
    "Actual Price(INR)/Quintal": 1145,
    "Percentage Difference": "-7.96%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 1244,
    "Actual Price(INR)/Quintal": 1220,
    "Percentage Difference": "-1.94%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 1226,
    "Actual Price(INR)/Quintal": 1222,
    "Percentage Difference": "-0.33%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 1238,
    "Actual Price(INR)/Quintal": 1189,
    "Percentage Difference": "-4.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 1181,
    "Actual Price(INR)/Quintal": 1133,
    "Percentage Difference": "-4.24%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 1138,
    "Actual Price(INR)/Quintal": 1160,
    "Percentage Difference": "1.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 1137,
    "Actual Price(INR)/Quintal": 1239,
    "Percentage Difference": "8.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 1242,
    "Actual Price(INR)/Quintal": 1268,
    "Percentage Difference": "2.05%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 1255,
    "Actual Price(INR)/Quintal": 1386,
    "Percentage Difference": "9.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 1414,
    "Actual Price(INR)/Quintal": 1474,
    "Percentage Difference": "4.07%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 1465,
    "Actual Price(INR)/Quintal": 1552,
    "Percentage Difference": "5.61%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 1557,
    "Actual Price(INR)/Quintal": 1601,
    "Percentage Difference": "2.75%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 1605,
    "Actual Price(INR)/Quintal": 1531,
    "Percentage Difference": "-4.83%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 1560,
    "Actual Price(INR)/Quintal": 1476,
    "Percentage Difference": "-5.69%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 1492,
    "Actual Price(INR)/Quintal": 1584,
    "Percentage Difference": "5.81%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 1564,
    "Actual Price(INR)/Quintal": 1696,
    "Percentage Difference": "7.83%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 1655,
    "Actual Price(INR)/Quintal": 1695,
    "Percentage Difference": "2.36%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 1688,
    "Actual Price(INR)/Quintal": 1696,
    "Percentage Difference": "0.50%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 1719,
    "Actual Price(INR)/Quintal": 1717,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 1825,
    "Actual Price(INR)/Quintal": 1835,
    "Percentage Difference": "0.55%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 1814,
    "Actual Price(INR)/Quintal": 1777,
    "Percentage Difference": "-2.08%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 1612,
    "Actual Price(INR)/Quintal": 1663,
    "Percentage Difference": "3.07%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 1626,
    "Actual Price(INR)/Quintal": 1696,
    "Percentage Difference": "4.13%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 1694,
    "Actual Price(INR)/Quintal": 1696,
    "Percentage Difference": "0.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 1614,
    "Actual Price(INR)/Quintal": 1607,
    "Percentage Difference": "-0.58%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 1596,
    "Actual Price(INR)/Quintal": 1580,
    "Percentage Difference": "-1.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 1502,
    "Actual Price(INR)/Quintal": 1665,
    "Percentage Difference": "9.79%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 1377,
    "Actual Price(INR)/Quintal": 1324,
    "Percentage Difference": "-4.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 1404,
    "Actual Price(INR)/Quintal": 1364,
    "Percentage Difference": "-2.93%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 1369,
    "Actual Price(INR)/Quintal": 1298,
    "Percentage Difference": "-5.47%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 1251,
    "Actual Price(INR)/Quintal": 1287,
    "Percentage Difference": "2.80%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 1309,
    "Actual Price(INR)/Quintal": 1278,
    "Percentage Difference": "-2.42%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 1291,
    "Actual Price(INR)/Quintal": 1222,
    "Percentage Difference": "-5.65%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 1231,
    "Actual Price(INR)/Quintal": 1305,
    "Percentage Difference": "5.67%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 1342,
    "Actual Price(INR)/Quintal": 1405,
    "Percentage Difference": "4.48%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 1375,
    "Actual Price(INR)/Quintal": 1363,
    "Percentage Difference": "-0.01%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 1395,
    "Actual Price(INR)/Quintal": 1321,
    "Percentage Difference": "-5.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 1339,
    "Actual Price(INR)/Quintal": 1397,
    "Percentage Difference": "4.15%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 1396,
    "Actual Price(INR)/Quintal": 1342,
    "Percentage Difference": "-4.02%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 1314,
    "Actual Price(INR)/Quintal": 1267,
    "Percentage Difference": "-3.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 1297,
    "Actual Price(INR)/Quintal": 1186,
    "Percentage Difference": "-9.94%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 1090,
    "Actual Price(INR)/Quintal": 1195,
    "Percentage Difference": "8.78%",
    "YEAR": 2016
  }
];
