export const Groundnut = [
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":6340,"Actual Price(INR)/Quintal":6501,"Percentage Difference":"2.48%","YEAR":2024},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":6459,"Actual Price(INR)/Quintal":6816,"Percentage Difference":"5.24%","YEAR":2024},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":6616,"Actual Price(INR)/Quintal":6576,"Percentage Difference":"-0.61%","YEAR":2024},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":6695,"Actual Price(INR)/Quintal":6574,"Percentage Difference":"-1.84%","YEAR":2024},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":6551,"Actual Price(INR)/Quintal":6471,"Percentage Difference":"-1.24%","YEAR":2024},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":6372,"Actual Price(INR)/Quintal":6299,"Percentage Difference":"-1.16%","YEAR":2024},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":6193,"Actual Price(INR)/Quintal":6315,"Percentage Difference":"1.93%","YEAR":2024},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":6216,"Actual Price(INR)/Quintal":6408,"Percentage Difference":"3.00%","YEAR":2024},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6206,"Actual Price(INR)/Quintal":6349,"Percentage Difference":"2.25%","YEAR":2024},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":6239,"Actual Price(INR)/Quintal":6348,"Percentage Difference":"1.72%","YEAR":2024},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":6317,"Actual Price(INR)/Quintal":6424,"Percentage Difference":"1.67%","YEAR":2024},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":6407,"Actual Price(INR)/Quintal":6393,"Percentage Difference":"-0.22%","YEAR":2024},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":6416,"Actual Price(INR)/Quintal":6463,"Percentage Difference":"0.73%","YEAR":2024},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":6359,"Actual Price(INR)/Quintal":6494,"Percentage Difference":"2.08%","YEAR":2024},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":6318,"Actual Price(INR)/Quintal":6547,"Percentage Difference":"3.50%","YEAR":2024},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":6344,"Actual Price(INR)/Quintal":6423,"Percentage Difference":"1.23%","YEAR":2024},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":6540,"Actual Price(INR)/Quintal":6604,"Percentage Difference":"0.97%","YEAR":2024},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":6503,"Actual Price(INR)/Quintal":6467,"Percentage Difference":"-0.56%","YEAR":2024},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":6491,"Actual Price(INR)/Quintal":6505,"Percentage Difference":"0.22%","YEAR":2024},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":6498,"Actual Price(INR)/Quintal":6388,"Percentage Difference":"-1.72%","YEAR":2024},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":6292,"Actual Price(INR)/Quintal":6108,"Percentage Difference":"-3.01%","YEAR":2024},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":6172,"Actual Price(INR)/Quintal":6278,"Percentage Difference":"1.69%","YEAR":2024},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":6130,"Actual Price(INR)/Quintal":6105,"Percentage Difference":"-0.41%","YEAR":2024},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":6142,"Actual Price(INR)/Quintal":6092,"Percentage Difference":"-0.82%","YEAR":2024},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":5893,"Actual Price(INR)/Quintal":5915,"Percentage Difference":"0.37%","YEAR":2024},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":5926,"Actual Price(INR)/Quintal":5884,"Percentage Difference":"-0.71%","YEAR":2024},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":5815,"Actual Price(INR)/Quintal":5913,"Percentage Difference":"1.66%","YEAR":2024},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":5880,"Actual Price(INR)/Quintal":5812,"Percentage Difference":"-1.17%","YEAR":2024},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":5857,"Actual Price(INR)/Quintal":5845,"Percentage Difference":"-0.21%","YEAR":2024},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":5852,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":5892,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":5803,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":5777,"Actual Price(INR)/Quintal":null,"Percentage Difference":null,"YEAR":2024},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":5918,"Actual Price(INR)/Quintal":6106,"Percentage Difference":"3.08%","YEAR":2023},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":5989,"Actual Price(INR)/Quintal":6094,"Percentage Difference":"1.72%","YEAR":2023},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":5979,"Actual Price(INR)/Quintal":5999,"Percentage Difference":"0.33%","YEAR":2023},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":6475,"Actual Price(INR)/Quintal":6669,"Percentage Difference":"2.91%","YEAR":2023},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":6749,"Actual Price(INR)/Quintal":6841,"Percentage Difference":"1.34%","YEAR":2023},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":6855,"Actual Price(INR)/Quintal":6868,"Percentage Difference":"0.19%","YEAR":2023},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":6883,"Actual Price(INR)/Quintal":6774,"Percentage Difference":"-1.61%","YEAR":2023},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":6781,"Actual Price(INR)/Quintal":6772,"Percentage Difference":"-0.13%","YEAR":2023},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6779,"Actual Price(INR)/Quintal":6330,"Percentage Difference":"-7.09%","YEAR":2023},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":6288,"Actual Price(INR)/Quintal":6200,"Percentage Difference":"-1.42%","YEAR":2023},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":6289,"Actual Price(INR)/Quintal":6328,"Percentage Difference":"0.62%","YEAR":2023},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":6295,"Actual Price(INR)/Quintal":6311,"Percentage Difference":"0.25%","YEAR":2023},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":6267,"Actual Price(INR)/Quintal":6352,"Percentage Difference":"1.34%","YEAR":2023},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":6228,"Actual Price(INR)/Quintal":6278,"Percentage Difference":"0.80%","YEAR":2023},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":6227,"Actual Price(INR)/Quintal":6059,"Percentage Difference":"-2.77%","YEAR":2023},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":6025,"Actual Price(INR)/Quintal":6077,"Percentage Difference":"0.86%","YEAR":2023},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":6091,"Actual Price(INR)/Quintal":5960,"Percentage Difference":"-2.20%","YEAR":2023},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":6022,"Actual Price(INR)/Quintal":6209,"Percentage Difference":"3.01%","YEAR":2023},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":6241,"Actual Price(INR)/Quintal":6392,"Percentage Difference":"2.36%","YEAR":2023},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":6205,"Actual Price(INR)/Quintal":6303,"Percentage Difference":"1.55%","YEAR":2023},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":6241,"Actual Price(INR)/Quintal":6266,"Percentage Difference":"0.40%","YEAR":2023},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":6213,"Actual Price(INR)/Quintal":6109,"Percentage Difference":"-1.70%","YEAR":2023},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":6215,"Actual Price(INR)/Quintal":6268,"Percentage Difference":"0.85%","YEAR":2023},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":6218,"Actual Price(INR)/Quintal":6287,"Percentage Difference":"1.10%","YEAR":2023},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":6195,"Actual Price(INR)/Quintal":6196,"Percentage Difference":"0.02%","YEAR":2023},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":6203,"Actual Price(INR)/Quintal":6263,"Percentage Difference":"0.96%","YEAR":2023},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":6208,"Actual Price(INR)/Quintal":6482,"Percentage Difference":"4.23%","YEAR":2023},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":6433,"Actual Price(INR)/Quintal":6441,"Percentage Difference":"0.12%","YEAR":2023},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":6369,"Actual Price(INR)/Quintal":6234,"Percentage Difference":"-2.17%","YEAR":2023},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":6200,"Actual Price(INR)/Quintal":6304,"Percentage Difference":"1.65%","YEAR":2023},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":6233,"Actual Price(INR)/Quintal":6411,"Percentage Difference":"2.78%","YEAR":2023},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":6270,"Actual Price(INR)/Quintal":6427,"Percentage Difference":"2.44%","YEAR":2023},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":6333,"Actual Price(INR)/Quintal":7014,"Percentage Difference":"9.71%","YEAR":2023},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":7006,"Actual Price(INR)/Quintal":6760,"Percentage Difference":"-3.64%","YEAR":2023},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":6638,"Actual Price(INR)/Quintal":6799,"Percentage Difference":"2.37%","YEAR":2023},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":6616,"Actual Price(INR)/Quintal":6516,"Percentage Difference":"-1.53%","YEAR":2023},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":6392,"Actual Price(INR)/Quintal":6711,"Percentage Difference":"4.75%","YEAR":2023},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":6716,"Actual Price(INR)/Quintal":6818,"Percentage Difference":"1.50%","YEAR":2023},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":6777,"Actual Price(INR)/Quintal":6603,"Percentage Difference":"-2.64%","YEAR":2023},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":6600,"Actual Price(INR)/Quintal":6472,"Percentage Difference":"-1.98%","YEAR":2023},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":6339,"Actual Price(INR)/Quintal":6389,"Percentage Difference":"0.78%","YEAR":2023},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":6348,"Actual Price(INR)/Quintal":6497,"Percentage Difference":"2.29%","YEAR":2023},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":6410,"Actual Price(INR)/Quintal":6513,"Percentage Difference":"1.58%","YEAR":2023},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":6473,"Actual Price(INR)/Quintal":6540,"Percentage Difference":"1.02%","YEAR":2023},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":6405,"Actual Price(INR)/Quintal":6415,"Percentage Difference":"0.16%","YEAR":2023},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6491,"Actual Price(INR)/Quintal":6372,"Percentage Difference":"-1.87%","YEAR":2023},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6334,"Actual Price(INR)/Quintal":6359,"Percentage Difference":"0.39%","YEAR":2023},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6366,"Actual Price(INR)/Quintal":6358,"Percentage Difference":"-0.13%","YEAR":2023},
{ "Year : Month Week#":"Janwk1","Predicted Price(INR)/Quintal":5699,"Actual Price(INR)/Quintal":5721,"Percentage Difference":"0.38%","YEAR":2022},
{ "Year : Month Week#":"Janwk2","Predicted Price(INR)/Quintal":5704,"Actual Price(INR)/Quintal":5976,"Percentage Difference":"4.55%","YEAR":2022},
{ "Year : Month Week#":"Janwk3","Predicted Price(INR)/Quintal":5894,"Actual Price(INR)/Quintal":6042,"Percentage Difference":"2.45%","YEAR":2022},
{ "Year : Month Week#":"Janwk4","Predicted Price(INR)/Quintal":5906,"Actual Price(INR)/Quintal":5989,"Percentage Difference":"1.39%","YEAR":2022},
{ "Year : Month Week#":"Febwk1","Predicted Price(INR)/Quintal":5759,"Actual Price(INR)/Quintal":5776,"Percentage Difference":"0.29%","YEAR":2022},
{ "Year : Month Week#":"Febwk2","Predicted Price(INR)/Quintal":5877,"Actual Price(INR)/Quintal":5985,"Percentage Difference":"1.80%","YEAR":2022},
{ "Year : Month Week#":"Febwk3","Predicted Price(INR)/Quintal":5924,"Actual Price(INR)/Quintal":5957,"Percentage Difference":"0.55%","YEAR":2022},
{ "Year : Month Week#":"Febwk4","Predicted Price(INR)/Quintal":5949,"Actual Price(INR)/Quintal":5913,"Percentage Difference":"-0.61%","YEAR":2022},
{ "Year : Month Week#":"Marwk1","Predicted Price(INR)/Quintal":6143,"Actual Price(INR)/Quintal":6208,"Percentage Difference":"1.05%","YEAR":2022},
{ "Year : Month Week#":"Marwk2","Predicted Price(INR)/Quintal":6140,"Actual Price(INR)/Quintal":6182,"Percentage Difference":"0.68%","YEAR":2022},
{ "Year : Month Week#":"Marwk3","Predicted Price(INR)/Quintal":6043,"Actual Price(INR)/Quintal":5947,"Percentage Difference":"-1.61%","YEAR":2022},
{ "Year : Month Week#":"Marwk4","Predicted Price(INR)/Quintal":6035,"Actual Price(INR)/Quintal":6093,"Percentage Difference":"0.95%","YEAR":2022},
{ "Year : Month Week#":"Aprwk1","Predicted Price(INR)/Quintal":6054,"Actual Price(INR)/Quintal":5854,"Percentage Difference":"-3.42%","YEAR":2022},
{ "Year : Month Week#":"Aprwk2","Predicted Price(INR)/Quintal":5863,"Actual Price(INR)/Quintal":5829,"Percentage Difference":"-0.58%","YEAR":2022},
{ "Year : Month Week#":"Aprwk3","Predicted Price(INR)/Quintal":5914,"Actual Price(INR)/Quintal":5939,"Percentage Difference":"0.42%","YEAR":2022},
{ "Year : Month Week#":"Aprwk4","Predicted Price(INR)/Quintal":5856,"Actual Price(INR)/Quintal":5871,"Percentage Difference":"0.26%","YEAR":2022},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":5841,"Actual Price(INR)/Quintal":5827,"Percentage Difference":"-0.24%","YEAR":2022},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":5879,"Actual Price(INR)/Quintal":5830,"Percentage Difference":"-0.84%","YEAR":2022},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":5775,"Actual Price(INR)/Quintal":5683,"Percentage Difference":"-1.62%","YEAR":2022},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":5584,"Actual Price(INR)/Quintal":5612,"Percentage Difference":"0.50%","YEAR":2022},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":5577,"Actual Price(INR)/Quintal":5621,"Percentage Difference":"0.78%","YEAR":2022},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":5538,"Actual Price(INR)/Quintal":5592,"Percentage Difference":"0.97%","YEAR":2022},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":5506,"Actual Price(INR)/Quintal":5569,"Percentage Difference":"1.13%","YEAR":2022},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":5522,"Actual Price(INR)/Quintal":5621,"Percentage Difference":"1.76%","YEAR":2022},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":5556,"Actual Price(INR)/Quintal":5655,"Percentage Difference":"1.75%","YEAR":2022},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":5674,"Actual Price(INR)/Quintal":5681,"Percentage Difference":"0.12%","YEAR":2022},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":5668,"Actual Price(INR)/Quintal":5766,"Percentage Difference":"1.70%","YEAR":2022},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":5891,"Actual Price(INR)/Quintal":5954,"Percentage Difference":"1.06%","YEAR":2022},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":5889,"Actual Price(INR)/Quintal":5732,"Percentage Difference":"-2.74%","YEAR":2022},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":5819,"Actual Price(INR)/Quintal":5720,"Percentage Difference":"-1.73%","YEAR":2022},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":5846,"Actual Price(INR)/Quintal":5900,"Percentage Difference":"0.92%","YEAR":2022},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":5895,"Actual Price(INR)/Quintal":6049,"Percentage Difference":"2.55%","YEAR":2022},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":5930,"Actual Price(INR)/Quintal":6151,"Percentage Difference":"3.59%","YEAR":2022},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":5722,"Actual Price(INR)/Quintal":5623,"Percentage Difference":"-1.76%","YEAR":2022},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":5780,"Actual Price(INR)/Quintal":5628,"Percentage Difference":"-2.70%","YEAR":2022},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":5617,"Actual Price(INR)/Quintal":5760,"Percentage Difference":"2.48%","YEAR":2022},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":5669,"Actual Price(INR)/Quintal":5741,"Percentage Difference":"1.25%","YEAR":2022},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":5674,"Actual Price(INR)/Quintal":5853,"Percentage Difference":"3.06%","YEAR":2022},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":5730,"Actual Price(INR)/Quintal":5793,"Percentage Difference":"1.09%","YEAR":2022},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":5711,"Actual Price(INR)/Quintal":5762,"Percentage Difference":"0.89%","YEAR":2022},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":5749,"Actual Price(INR)/Quintal":5843,"Percentage Difference":"1.61%","YEAR":2022},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":5764,"Actual Price(INR)/Quintal":5734,"Percentage Difference":"-0.52%","YEAR":2022},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":5684,"Actual Price(INR)/Quintal":5840,"Percentage Difference":"2.67%","YEAR":2022},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":5707,"Actual Price(INR)/Quintal":6003,"Percentage Difference":"4.93%","YEAR":2022},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":5822,"Actual Price(INR)/Quintal":6148,"Percentage Difference":"5.30%","YEAR":2022},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":6197,"Actual Price(INR)/Quintal":6199,"Percentage Difference":"0.03%","YEAR":2022},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":6110,"Actual Price(INR)/Quintal":5960,"Percentage Difference":"-2.52%","YEAR":2022},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":6034,"Actual Price(INR)/Quintal":5946,"Percentage Difference":"-1.48%","YEAR":2022},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":4582,"Actual Price(INR)/Quintal":4637,"Percentage Difference":"1.19%","YEAR":2021},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":4555,"Actual Price(INR)/Quintal":4594,"Percentage Difference":"0.85%","YEAR":2021},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":4719,"Actual Price(INR)/Quintal":4851,"Percentage Difference":"2.72%","YEAR":2021},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":5303,"Actual Price(INR)/Quintal":5404,"Percentage Difference":"1.87%","YEAR":2021},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":5574,"Actual Price(INR)/Quintal":5665,"Percentage Difference":"1.61%","YEAR":2021},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":5637,"Actual Price(INR)/Quintal":5822,"Percentage Difference":"3.18%","YEAR":2021},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":5958,"Actual Price(INR)/Quintal":6108,"Percentage Difference":"2.46%","YEAR":2021},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":6265,"Actual Price(INR)/Quintal":6512,"Percentage Difference":"3.79%","YEAR":2021},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":6240,"Actual Price(INR)/Quintal":6300,"Percentage Difference":"0.95%","YEAR":2021},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":6288,"Actual Price(INR)/Quintal":6250,"Percentage Difference":"-0.61%","YEAR":2021},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":6287,"Actual Price(INR)/Quintal":6320,"Percentage Difference":"0.52%","YEAR":2021},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":6325,"Actual Price(INR)/Quintal":6105,"Percentage Difference":"-3.60%","YEAR":2021},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":6053,"Actual Price(INR)/Quintal":5782,"Percentage Difference":"-4.69%","YEAR":2021},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":5629,"Actual Price(INR)/Quintal":5389,"Percentage Difference":"-4.45%","YEAR":2021},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":5311,"Actual Price(INR)/Quintal":5391,"Percentage Difference":"1.48%","YEAR":2021},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":5388,"Actual Price(INR)/Quintal":5412,"Percentage Difference":"0.44%","YEAR":2021},
{ "Year : Month Week#":"Maywk1","Predicted Price(INR)/Quintal":5377,"Actual Price(INR)/Quintal":5209,"Percentage Difference":"-3.23%","YEAR":2021},
{ "Year : Month Week#":"Maywk2","Predicted Price(INR)/Quintal":5046,"Actual Price(INR)/Quintal":5109,"Percentage Difference":"1.23%","YEAR":2021},
{ "Year : Month Week#":"Maywk3","Predicted Price(INR)/Quintal":5065,"Actual Price(INR)/Quintal":5232,"Percentage Difference":"3.19%","YEAR":2021},
{ "Year : Month Week#":"Maywk4","Predicted Price(INR)/Quintal":5211,"Actual Price(INR)/Quintal":5397,"Percentage Difference":"3.45%","YEAR":2021},
{ "Year : Month Week#":"Junwk1","Predicted Price(INR)/Quintal":5218,"Actual Price(INR)/Quintal":5106,"Percentage Difference":"-2.19%","YEAR":2021},
{ "Year : Month Week#":"Junwk2","Predicted Price(INR)/Quintal":5207,"Actual Price(INR)/Quintal":5258,"Percentage Difference":"0.97%","YEAR":2021},
{ "Year : Month Week#":"Junwk3","Predicted Price(INR)/Quintal":5215,"Actual Price(INR)/Quintal":5124,"Percentage Difference":"-1.78%","YEAR":2021},
{ "Year : Month Week#":"Junwk4","Predicted Price(INR)/Quintal":5137,"Actual Price(INR)/Quintal":5026,"Percentage Difference":"-2.21%","YEAR":2021},
{ "Year : Month Week#":"Julwk1","Predicted Price(INR)/Quintal":4834,"Actual Price(INR)/Quintal":4762,"Percentage Difference":"-1.51%","YEAR":2021},
{ "Year : Month Week#":"Julwk2","Predicted Price(INR)/Quintal":4733,"Actual Price(INR)/Quintal":4798,"Percentage Difference":"1.35%","YEAR":2021},
{ "Year : Month Week#":"Julwk3","Predicted Price(INR)/Quintal":4932,"Actual Price(INR)/Quintal":5090,"Percentage Difference":"3.10%","YEAR":2021},
{ "Year : Month Week#":"Julwk4","Predicted Price(INR)/Quintal":4922,"Actual Price(INR)/Quintal":5016,"Percentage Difference":"1.87%","YEAR":2021},
{ "Year : Month Week#":"Augwk1","Predicted Price(INR)/Quintal":4942,"Actual Price(INR)/Quintal":5046,"Percentage Difference":"2.06%","YEAR":2021},
{ "Year : Month Week#":"Augwk2","Predicted Price(INR)/Quintal":5084,"Actual Price(INR)/Quintal":5225,"Percentage Difference":"2.70%","YEAR":2021},
{ "Year : Month Week#":"Augwk3","Predicted Price(INR)/Quintal":5240,"Actual Price(INR)/Quintal":5392,"Percentage Difference":"2.82%","YEAR":2021},
{ "Year : Month Week#":"Augwk4","Predicted Price(INR)/Quintal":5347,"Actual Price(INR)/Quintal":5378,"Percentage Difference":"0.58%","YEAR":2021},
{ "Year : Month Week#":"Sepwk1","Predicted Price(INR)/Quintal":5366,"Actual Price(INR)/Quintal":5362,"Percentage Difference":"-0.07%","YEAR":2021},
{ "Year : Month Week#":"Sepwk2","Predicted Price(INR)/Quintal":5367,"Actual Price(INR)/Quintal":5434,"Percentage Difference":"1.23%","YEAR":2021},
{ "Year : Month Week#":"Sepwk3","Predicted Price(INR)/Quintal":5392,"Actual Price(INR)/Quintal":5220,"Percentage Difference":"-3.30%","YEAR":2021},
{ "Year : Month Week#":"Sepwk4","Predicted Price(INR)/Quintal":5354,"Actual Price(INR)/Quintal":5236,"Percentage Difference":"-2.25%","YEAR":2021},
{ "Year : Month Week#":"Octwk1","Predicted Price(INR)/Quintal":5237,"Actual Price(INR)/Quintal":5336,"Percentage Difference":"1.86%","YEAR":2021},
{ "Year : Month Week#":"Octwk2","Predicted Price(INR)/Quintal":5239,"Actual Price(INR)/Quintal":5405,"Percentage Difference":"3.07%","YEAR":2021},
{ "Year : Month Week#":"Octwk3","Predicted Price(INR)/Quintal":5338,"Actual Price(INR)/Quintal":5317,"Percentage Difference":"-0.39%","YEAR":2021},
{ "Year : Month Week#":"Octwk4","Predicted Price(INR)/Quintal":5324,"Actual Price(INR)/Quintal":5402,"Percentage Difference":"1.44%","YEAR":2021},
{ "Year : Month Week#":"Novwk1","Predicted Price(INR)/Quintal":5385,"Actual Price(INR)/Quintal":5397,"Percentage Difference":"0.22%","YEAR":2021},
{ "Year : Month Week#":"Novwk2","Predicted Price(INR)/Quintal":5305,"Actual Price(INR)/Quintal":5372,"Percentage Difference":"1.25%","YEAR":2021},
{ "Year : Month Week#":"Novwk3","Predicted Price(INR)/Quintal":5272,"Actual Price(INR)/Quintal":5285,"Percentage Difference":"0.25%","YEAR":2021},
{ "Year : Month Week#":"Novwk4","Predicted Price(INR)/Quintal":5277,"Actual Price(INR)/Quintal":5401,"Percentage Difference":"2.30%","YEAR":2021},
{ "Year : Month Week#":"Decwk1","Predicted Price(INR)/Quintal":5363,"Actual Price(INR)/Quintal":5551,"Percentage Difference":"3.39%","YEAR":2021},
{ "Year : Month Week#":"Decwk2","Predicted Price(INR)/Quintal":5545,"Actual Price(INR)/Quintal":5480,"Percentage Difference":"-1.19%","YEAR":2021},
{ "Year : Month Week#":"Decwk3","Predicted Price(INR)/Quintal":5491,"Actual Price(INR)/Quintal":5704,"Percentage Difference":"3.73%","YEAR":2021},
{ "Year : Month Week#":"Decwk4","Predicted Price(INR)/Quintal":5665,"Actual Price(INR)/Quintal":5824,"Percentage Difference":"2.73%","YEAR":2021},
{ "Year : Month Week#":"JanWk1","Predicted Price(INR)/Quintal":4099,"Actual Price(INR)/Quintal":4027,"Percentage Difference":"-1.79%","YEAR":2020},
{ "Year : Month Week#":"JanWk2","Predicted Price(INR)/Quintal":4120,"Actual Price(INR)/Quintal":4257,"Percentage Difference":"3.22%","YEAR":2020},
{ "Year : Month Week#":"JanWk3","Predicted Price(INR)/Quintal":4102,"Actual Price(INR)/Quintal":4448,"Percentage Difference":"7.78%","YEAR":2020},
{ "Year : Month Week#":"JanWk4","Predicted Price(INR)/Quintal":4303,"Actual Price(INR)/Quintal":4478,"Percentage Difference":"3.91%","YEAR":2020},
{ "Year : Month Week#":"FebWk1","Predicted Price(INR)/Quintal":4434,"Actual Price(INR)/Quintal":4762,"Percentage Difference":"6.89%","YEAR":2020},
{ "Year : Month Week#":"FebWk2","Predicted Price(INR)/Quintal":4375,"Actual Price(INR)/Quintal":4669,"Percentage Difference":"6.30%","YEAR":2020},
{ "Year : Month Week#":"FebWk3","Predicted Price(INR)/Quintal":4925,"Actual Price(INR)/Quintal":5145,"Percentage Difference":"4.28%","YEAR":2020},
{ "Year : Month Week#":"FebWk4","Predicted Price(INR)/Quintal":5221,"Actual Price(INR)/Quintal":5295,"Percentage Difference":"1.40%","YEAR":2020},
{ "Year : Month Week#":"MarWk1","Predicted Price(INR)/Quintal":5095,"Actual Price(INR)/Quintal":5235,"Percentage Difference":"2.67%","YEAR":2020},
{ "Year : Month Week#":"MarWk2","Predicted Price(INR)/Quintal":5132,"Actual Price(INR)/Quintal":5502,"Percentage Difference":"6.72%","YEAR":2020},
{ "Year : Month Week#":"MarWk3","Predicted Price(INR)/Quintal":5568,"Actual Price(INR)/Quintal":5578,"Percentage Difference":"0.18%","YEAR":2020},
{ "Year : Month Week#":"MarWk4","Predicted Price(INR)/Quintal":5591,"Actual Price(INR)/Quintal":5500,"Percentage Difference":"-1.65%","YEAR":2020},
{ "Year : Month Week#":"AprWk1","Predicted Price(INR)/Quintal":5504,"Actual Price(INR)/Quintal":5570,"Percentage Difference":"1.18%","YEAR":2020},
{ "Year : Month Week#":"AprWk2","Predicted Price(INR)/Quintal":5506,"Actual Price(INR)/Quintal":5534,"Percentage Difference":"0.51%","YEAR":2020},
{ "Year : Month Week#":"AprWk3","Predicted Price(INR)/Quintal":5544,"Actual Price(INR)/Quintal":5476,"Percentage Difference":"-1.24%","YEAR":2020},
{ "Year : Month Week#":"AprWk4","Predicted Price(INR)/Quintal":5334,"Actual Price(INR)/Quintal":5163,"Percentage Difference":"-3.31%","YEAR":2020},
{ "Year : Month Week#":"MayWk1","Predicted Price(INR)/Quintal":5170,"Actual Price(INR)/Quintal":5065,"Percentage Difference":"-2.07%","YEAR":2020},
{ "Year : Month Week#":"MayWk2","Predicted Price(INR)/Quintal":5062,"Actual Price(INR)/Quintal":5247,"Percentage Difference":"3.53%","YEAR":2020},
{ "Year : Month Week#":"MayWk3","Predicted Price(INR)/Quintal":5167,"Actual Price(INR)/Quintal":5222,"Percentage Difference":"1.05%","YEAR":2020},
{ "Year : Month Week#":"MayWk4","Predicted Price(INR)/Quintal":5197,"Actual Price(INR)/Quintal":5271,"Percentage Difference":"1.40%","YEAR":2020},
{ "Year : Month Week#":"JunWk1","Predicted Price(INR)/Quintal":5191,"Actual Price(INR)/Quintal":5227,"Percentage Difference":"0.69%","YEAR":2020},
{ "Year : Month Week#":"JunWk2","Predicted Price(INR)/Quintal":5156,"Actual Price(INR)/Quintal":5261,"Percentage Difference":"2.00%","YEAR":2020},
{ "Year : Month Week#":"JunWk3","Predicted Price(INR)/Quintal":5169,"Actual Price(INR)/Quintal":5219,"Percentage Difference":"0.96%","YEAR":2020},
{ "Year : Month Week#":"JunWk4","Predicted Price(INR)/Quintal":5165,"Actual Price(INR)/Quintal":5365,"Percentage Difference":"3.73%","YEAR":2020},
{ "Year : Month Week#":"JulWk1","Predicted Price(INR)/Quintal":5378,"Actual Price(INR)/Quintal":5575,"Percentage Difference":"3.53%","YEAR":2020},
{ "Year : Month Week#":"JulWk2","Predicted Price(INR)/Quintal":5697,"Actual Price(INR)/Quintal":5699,"Percentage Difference":"0.04%","YEAR":2020},
{ "Year : Month Week#":"JulWk3","Predicted Price(INR)/Quintal":5583,"Actual Price(INR)/Quintal":5655,"Percentage Difference":"1.27%","YEAR":2020},
{ "Year : Month Week#":"JulWk4","Predicted Price(INR)/Quintal":5587,"Actual Price(INR)/Quintal":5493,"Percentage Difference":"-1.71%","YEAR":2020},
{ "Year : Month Week#":"AugWk1","Predicted Price(INR)/Quintal":5480,"Actual Price(INR)/Quintal":5335,"Percentage Difference":"-2.72%","YEAR":2020},
{ "Year : Month Week#":"AugWk2","Predicted Price(INR)/Quintal":5383,"Actual Price(INR)/Quintal":5406,"Percentage Difference":"0.43%","YEAR":2020},
{ "Year : Month Week#":"AugWk3","Predicted Price(INR)/Quintal":5455,"Actual Price(INR)/Quintal":5306,"Percentage Difference":"-2.81%","YEAR":2020},
{ "Year : Month Week#":"AugWk4","Predicted Price(INR)/Quintal":5147,"Actual Price(INR)/Quintal":4985,"Percentage Difference":"-3.25%","YEAR":2020},
{ "Year : Month Week#":"SepWk1","Predicted Price(INR)/Quintal":4711,"Actual Price(INR)/Quintal":4587,"Percentage Difference":"-2.70%","YEAR":2020},
{ "Year : Month Week#":"SepWk2","Predicted Price(INR)/Quintal":4388,"Actual Price(INR)/Quintal":4290,"Percentage Difference":"-2.28%","YEAR":2020},
{ "Year : Month Week#":"SepWk3","Predicted Price(INR)/Quintal":4085,"Actual Price(INR)/Quintal":3974,"Percentage Difference":"-2.79%","YEAR":2020},
{ "Year : Month Week#":"SepWk4","Predicted Price(INR)/Quintal":3851,"Actual Price(INR)/Quintal":3764,"Percentage Difference":"-2.31%","YEAR":2020},
{ "Year : Month Week#":"OctWk1","Predicted Price(INR)/Quintal":3761,"Actual Price(INR)/Quintal":3650,"Percentage Difference":"-3.04%","YEAR":2020},
{ "Year : Month Week#":"OctWk2","Predicted Price(INR)/Quintal":3638,"Actual Price(INR)/Quintal":3812,"Percentage Difference":"4.56%","YEAR":2020},
{ "Year : Month Week#":"OctWk3","Predicted Price(INR)/Quintal":3969,"Actual Price(INR)/Quintal":4052,"Percentage Difference":"2.05%","YEAR":2020},
{ "Year : Month Week#":"OctWk4","Predicted Price(INR)/Quintal":4014,"Actual Price(INR)/Quintal":4201,"Percentage Difference":"4.45%","YEAR":2020},
{ "Year : Month Week#":"NovWk1","Predicted Price(INR)/Quintal":4324,"Actual Price(INR)/Quintal":4130,"Percentage Difference":"-4.70%","YEAR":2020},
{ "Year : Month Week#":"NovWk2","Predicted Price(INR)/Quintal":4182,"Actual Price(INR)/Quintal":4222,"Percentage Difference":"0.95%","YEAR":2020},
{ "Year : Month Week#":"NovWk3","Predicted Price(INR)/Quintal":4138,"Actual Price(INR)/Quintal":4341,"Percentage Difference":"4.68%","YEAR":2020},
{ "Year : Month Week#":"NovWk4","Predicted Price(INR)/Quintal":4416,"Actual Price(INR)/Quintal":4552,"Percentage Difference":"2.99%","YEAR":2020},
{ "Year : Month Week#":"DecWk1","Predicted Price(INR)/Quintal":4481,"Actual Price(INR)/Quintal":4532,"Percentage Difference":"1.13%","YEAR":2020},
{ "Year : Month Week#":"DecWk2","Predicted Price(INR)/Quintal":4402,"Actual Price(INR)/Quintal":4503,"Percentage Difference":"2.24%","YEAR":2020},
{ "Year : Month Week#":"DecWk3","Predicted Price(INR)/Quintal":4470,"Actual Price(INR)/Quintal":4478,"Percentage Difference":"0.18%","YEAR":2020},
{ "Year : Month Week#":"DecWk4","Predicted Price(INR)/Quintal":4422,"Actual Price(INR)/Quintal":4489,"Percentage Difference":"1.49%","YEAR":2020},
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 3730,
    "Actual Price(INR)/Quintal": 3814,
    "Percentage Difference": "2.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 3732,
    "Actual Price(INR)/Quintal": 4013,
    "Percentage Difference": "7.00%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 3934,
    "Actual Price(INR)/Quintal": 4280,
    "Percentage Difference": "8.08%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 4223,
    "Actual Price(INR)/Quintal": 4450,
    "Percentage Difference": "5.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 4338,
    "Actual Price(INR)/Quintal": 4502,
    "Percentage Difference": "3.64%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 4437,
    "Actual Price(INR)/Quintal": 4631,
    "Percentage Difference": "4.19%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 4525,
    "Actual Price(INR)/Quintal": 4708,
    "Percentage Difference": "3.89%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 4630,
    "Actual Price(INR)/Quintal": 4654,
    "Percentage Difference": "0.52%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 4629,
    "Actual Price(INR)/Quintal": 4630,
    "Percentage Difference": "0.02%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 4626,
    "Actual Price(INR)/Quintal": 4521,
    "Percentage Difference": "-2.32%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 4509,
    "Actual Price(INR)/Quintal": 4295,
    "Percentage Difference": "-4.98%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 4310,
    "Actual Price(INR)/Quintal": 4445,
    "Percentage Difference": "3.04%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 4341,
    "Actual Price(INR)/Quintal": 4375,
    "Percentage Difference": "0.78%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 4360,
    "Actual Price(INR)/Quintal": 4504,
    "Percentage Difference": "3.20%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 4434,
    "Actual Price(INR)/Quintal": 4537,
    "Percentage Difference": "2.27%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 4467,
    "Actual Price(INR)/Quintal": 4701,
    "Percentage Difference": "4.98%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 4772,
    "Actual Price(INR)/Quintal": 4902,
    "Percentage Difference": "2.65%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 4858,
    "Actual Price(INR)/Quintal": 5028,
    "Percentage Difference": "3.38%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 5071,
    "Actual Price(INR)/Quintal": 5201,
    "Percentage Difference": "2.50%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 5290,
    "Actual Price(INR)/Quintal": 5264,
    "Percentage Difference": "-0.49%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 5172,
    "Actual Price(INR)/Quintal": 5069,
    "Percentage Difference": "-2.03%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 5077,
    "Actual Price(INR)/Quintal": 5004,
    "Percentage Difference": "-1.46%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 5007,
    "Actual Price(INR)/Quintal": 5186,
    "Percentage Difference": "3.45%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 5214,
    "Actual Price(INR)/Quintal": 5257,
    "Percentage Difference": "0.82%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 5170,
    "Actual Price(INR)/Quintal": 5180,
    "Percentage Difference": "0.19%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 5177,
    "Actual Price(INR)/Quintal": 5219,
    "Percentage Difference": "0.80%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 5259,
    "Actual Price(INR)/Quintal": 5521,
    "Percentage Difference": "4.75%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 5475,
    "Actual Price(INR)/Quintal": 5479,
    "Percentage Difference": "0.07%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 5470,
    "Actual Price(INR)/Quintal": 5440,
    "Percentage Difference": "-0.55%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 5447,
    "Actual Price(INR)/Quintal": 5463,
    "Percentage Difference": "0.29%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 5441,
    "Actual Price(INR)/Quintal": 5469,
    "Percentage Difference": "0.51%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 5426,
    "Actual Price(INR)/Quintal": 5681,
    "Percentage Difference": "4.49%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 5678,
    "Actual Price(INR)/Quintal": 5880,
    "Percentage Difference": "3.44%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 5873,
    "Actual Price(INR)/Quintal": 6037,
    "Percentage Difference": "2.72%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 5968,
    "Actual Price(INR)/Quintal": 5969,
    "Percentage Difference": "0.02%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 5976,
    "Actual Price(INR)/Quintal": 6042,
    "Percentage Difference": "1.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 5937,
    "Actual Price(INR)/Quintal": 5670,
    "Percentage Difference": "-4.71%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 5652,
    "Actual Price(INR)/Quintal": 5370,
    "Percentage Difference": "-5.25%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 5645,
    "Actual Price(INR)/Quintal": 5352,
    "Percentage Difference": "-5.47%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 5052,
    "Actual Price(INR)/Quintal": 4859,
    "Percentage Difference": "-3.97%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 4279,
    "Actual Price(INR)/Quintal": 4407,
    "Percentage Difference": "2.90%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 4261,
    "Actual Price(INR)/Quintal": 4109,
    "Percentage Difference": "-3.70%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 4234,
    "Actual Price(INR)/Quintal": 4139,
    "Percentage Difference": "-2.30%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 4411,
    "Actual Price(INR)/Quintal": 4415,
    "Percentage Difference": "0.09%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 4032,
    "Actual Price(INR)/Quintal": 4077,
    "Percentage Difference": "1.10%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 4110,
    "Actual Price(INR)/Quintal": 4034,
    "Percentage Difference": "-1.88%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 4123,
    "Actual Price(INR)/Quintal": 4135,
    "Percentage Difference": "0.29%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 4144,
    "Actual Price(INR)/Quintal": 3999,
    "Percentage Difference": "-3.63%",
    "YEAR": 2019
  },
  {
    "Year : Month Week#": "JanWk1",
    "Predicted Price(INR)/Quintal": 3303,
    "Actual Price(INR)/Quintal": 3423,
    "Percentage Difference": "3.51%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk2",
    "Predicted Price(INR)/Quintal": 3454,
    "Actual Price(INR)/Quintal": 3542,
    "Percentage Difference": "2.48%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk3",
    "Predicted Price(INR)/Quintal": 3479,
    "Actual Price(INR)/Quintal": 3557,
    "Percentage Difference": "2.19%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JanWk4",
    "Predicted Price(INR)/Quintal": 3598,
    "Actual Price(INR)/Quintal": 3754,
    "Percentage Difference": "4.16%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk1",
    "Predicted Price(INR)/Quintal": 3685,
    "Actual Price(INR)/Quintal": 3745,
    "Percentage Difference": "1.60%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk2",
    "Predicted Price(INR)/Quintal": 3805,
    "Actual Price(INR)/Quintal": 4055,
    "Percentage Difference": "6.17%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk3",
    "Predicted Price(INR)/Quintal": 4007,
    "Actual Price(INR)/Quintal": 4025,
    "Percentage Difference": "0.45%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "FebWk4",
    "Predicted Price(INR)/Quintal": 4094,
    "Actual Price(INR)/Quintal": 3916,
    "Percentage Difference": "-4.55%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk1",
    "Predicted Price(INR)/Quintal": 3990,
    "Actual Price(INR)/Quintal": 3939,
    "Percentage Difference": "-1.29%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk2",
    "Predicted Price(INR)/Quintal": 3893,
    "Actual Price(INR)/Quintal": 3852,
    "Percentage Difference": "-1.06%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk3",
    "Predicted Price(INR)/Quintal": 3895,
    "Actual Price(INR)/Quintal": 3813,
    "Percentage Difference": "-2.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MarWk4",
    "Predicted Price(INR)/Quintal": 3898,
    "Actual Price(INR)/Quintal": 3841,
    "Percentage Difference": "-1.48%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk1",
    "Predicted Price(INR)/Quintal": 3877,
    "Actual Price(INR)/Quintal": 3784,
    "Percentage Difference": "-2.46%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk2",
    "Predicted Price(INR)/Quintal": 3818,
    "Actual Price(INR)/Quintal": 3615,
    "Percentage Difference": "-5.62%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk3",
    "Predicted Price(INR)/Quintal": 3605,
    "Actual Price(INR)/Quintal": 3450,
    "Percentage Difference": "-4.49%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AprWk4",
    "Predicted Price(INR)/Quintal": 3396,
    "Actual Price(INR)/Quintal": 3365,
    "Percentage Difference": "-0.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk1",
    "Predicted Price(INR)/Quintal": 3304,
    "Actual Price(INR)/Quintal": 3244,
    "Percentage Difference": "-1.85%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk2",
    "Predicted Price(INR)/Quintal": 3326,
    "Actual Price(INR)/Quintal": 3330,
    "Percentage Difference": "0.12%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk3",
    "Predicted Price(INR)/Quintal": 3306,
    "Actual Price(INR)/Quintal": 3471,
    "Percentage Difference": "4.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "MayWk4",
    "Predicted Price(INR)/Quintal": 3414,
    "Actual Price(INR)/Quintal": 3366,
    "Percentage Difference": "-1.43%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk1",
    "Predicted Price(INR)/Quintal": 3345,
    "Actual Price(INR)/Quintal": 3350,
    "Percentage Difference": "0.15%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk2",
    "Predicted Price(INR)/Quintal": 3346,
    "Actual Price(INR)/Quintal": 3246,
    "Percentage Difference": "-3.08%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk3",
    "Predicted Price(INR)/Quintal": 3326,
    "Actual Price(INR)/Quintal": 3347,
    "Percentage Difference": "0.63%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JunWk4",
    "Predicted Price(INR)/Quintal": 3288,
    "Actual Price(INR)/Quintal": 3341,
    "Percentage Difference": "1.59%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk1",
    "Predicted Price(INR)/Quintal": 3314,
    "Actual Price(INR)/Quintal": 3443,
    "Percentage Difference": "3.75%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk2",
    "Predicted Price(INR)/Quintal": 3419,
    "Actual Price(INR)/Quintal": 3420,
    "Percentage Difference": "0.03%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk3",
    "Predicted Price(INR)/Quintal": 3423,
    "Actual Price(INR)/Quintal": 3401,
    "Percentage Difference": "-0.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "JulWk4",
    "Predicted Price(INR)/Quintal": 3429,
    "Actual Price(INR)/Quintal": 3653,
    "Percentage Difference": "6.13%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk1",
    "Predicted Price(INR)/Quintal": 3513,
    "Actual Price(INR)/Quintal": 3738,
    "Percentage Difference": "6.02%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk2",
    "Predicted Price(INR)/Quintal": 3748,
    "Actual Price(INR)/Quintal": 3808,
    "Percentage Difference": "1.58%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk3",
    "Predicted Price(INR)/Quintal": 3767,
    "Actual Price(INR)/Quintal": 4002,
    "Percentage Difference": "5.87%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "AugWk4",
    "Predicted Price(INR)/Quintal": 3982,
    "Actual Price(INR)/Quintal": 4087,
    "Percentage Difference": "2.57%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk1",
    "Predicted Price(INR)/Quintal": 3982,
    "Actual Price(INR)/Quintal": 3812,
    "Percentage Difference": "-4.46%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk2",
    "Predicted Price(INR)/Quintal": 3857,
    "Actual Price(INR)/Quintal": 3654,
    "Percentage Difference": "-5.56%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk3",
    "Predicted Price(INR)/Quintal": 3621,
    "Actual Price(INR)/Quintal": 3805,
    "Percentage Difference": "4.84%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "SepWk4",
    "Predicted Price(INR)/Quintal": 3783,
    "Actual Price(INR)/Quintal": 3909,
    "Percentage Difference": "3.22%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk1",
    "Predicted Price(INR)/Quintal": 3812,
    "Actual Price(INR)/Quintal": 3838,
    "Percentage Difference": "0.68%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk2",
    "Predicted Price(INR)/Quintal": 3834,
    "Actual Price(INR)/Quintal": 3854,
    "Percentage Difference": "0.52%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk3",
    "Predicted Price(INR)/Quintal": 3856,
    "Actual Price(INR)/Quintal": 3837,
    "Percentage Difference": "-0.50%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "OctWk4",
    "Predicted Price(INR)/Quintal": 3862,
    "Actual Price(INR)/Quintal": 4062,
    "Percentage Difference": "4.92%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk1",
    "Predicted Price(INR)/Quintal": 4044,
    "Actual Price(INR)/Quintal": 4070,
    "Percentage Difference": "0.64%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk2",
    "Predicted Price(INR)/Quintal": 4037,
    "Actual Price(INR)/Quintal": 4167,
    "Percentage Difference": "3.12%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk3",
    "Predicted Price(INR)/Quintal": 4181,
    "Actual Price(INR)/Quintal": 4057,
    "Percentage Difference": "-3.06%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "NovWk4",
    "Predicted Price(INR)/Quintal": 4077,
    "Actual Price(INR)/Quintal": 3953,
    "Percentage Difference": "-3.14%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk1",
    "Predicted Price(INR)/Quintal": 3945,
    "Actual Price(INR)/Quintal": 3793,
    "Percentage Difference": "-4.01%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk2",
    "Predicted Price(INR)/Quintal": 3841,
    "Actual Price(INR)/Quintal": 3742,
    "Percentage Difference": "-2.65%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk3",
    "Predicted Price(INR)/Quintal": 3626,
    "Actual Price(INR)/Quintal": 3831,
    "Percentage Difference": "5.35%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "DecWk4",
    "Predicted Price(INR)/Quintal": 3802,
    "Actual Price(INR)/Quintal": 3721,
    "Percentage Difference": "-2.18%",
    "YEAR": 2018
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 4245,
    "Actual Price(INR)/Quintal": 4488,
    "Percentage Difference": "5.41%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 4350,
    "Actual Price(INR)/Quintal": 4622,
    "Percentage Difference": "5.88%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 4402,
    "Actual Price(INR)/Quintal": 4605,
    "Percentage Difference": "4.41%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 4467,
    "Actual Price(INR)/Quintal": 4947,
    "Percentage Difference": "9.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 4815,
    "Actual Price(INR)/Quintal": 4784,
    "Percentage Difference": "-0.65%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 4871,
    "Actual Price(INR)/Quintal": 4864,
    "Percentage Difference": "-0.14%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 4829,
    "Actual Price(INR)/Quintal": 4878,
    "Percentage Difference": "1.00%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 4886,
    "Actual Price(INR)/Quintal": 4977,
    "Percentage Difference": "1.83%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 4882,
    "Actual Price(INR)/Quintal": 4973,
    "Percentage Difference": "1.83%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 4902,
    "Actual Price(INR)/Quintal": 5219,
    "Percentage Difference": "6.07%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 4907,
    "Actual Price(INR)/Quintal": 5108,
    "Percentage Difference": "3.94%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 4908,
    "Actual Price(INR)/Quintal": 5210,
    "Percentage Difference": "5.80%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 5201,
    "Actual Price(INR)/Quintal": 5077,
    "Percentage Difference": "-2.44%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 5087,
    "Actual Price(INR)/Quintal": 5063,
    "Percentage Difference": "-0.47%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 5090,
    "Actual Price(INR)/Quintal": 4945,
    "Percentage Difference": "-2.93%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 4993,
    "Actual Price(INR)/Quintal": 5039,
    "Percentage Difference": "0.91%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 4987,
    "Actual Price(INR)/Quintal": 4812,
    "Percentage Difference": "-3.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 4815,
    "Actual Price(INR)/Quintal": 4627,
    "Percentage Difference": "-4.06%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 4619,
    "Actual Price(INR)/Quintal": 5032,
    "Percentage Difference": "8.21%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 5210,
    "Actual Price(INR)/Quintal": 4758,
    "Percentage Difference": "-9.50%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 4523,
    "Actual Price(INR)/Quintal": 4318,
    "Percentage Difference": "-4.75%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 4269,
    "Actual Price(INR)/Quintal": 4161,
    "Percentage Difference": "-2.60%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 4156,
    "Actual Price(INR)/Quintal": 4104,
    "Percentage Difference": "-1.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 4131,
    "Actual Price(INR)/Quintal": 4545,
    "Percentage Difference": "9.11%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 4766,
    "Actual Price(INR)/Quintal": 4466,
    "Percentage Difference": "-6.72%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 4356,
    "Actual Price(INR)/Quintal": 4246,
    "Percentage Difference": "-2.59%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 4123,
    "Actual Price(INR)/Quintal": 4034,
    "Percentage Difference": "-2.21%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 4020,
    "Actual Price(INR)/Quintal": 3968,
    "Percentage Difference": "-1.31%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 3995,
    "Actual Price(INR)/Quintal": 4173,
    "Percentage Difference": "4.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 4083,
    "Actual Price(INR)/Quintal": 4267,
    "Percentage Difference": "4.31%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 4384,
    "Actual Price(INR)/Quintal": 4388,
    "Percentage Difference": "0.09%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 4297,
    "Actual Price(INR)/Quintal": 4038,
    "Percentage Difference": "-6.41%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 4081,
    "Actual Price(INR)/Quintal": 3783,
    "Percentage Difference": "-7.88%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 3621,
    "Actual Price(INR)/Quintal": 3703,
    "Percentage Difference": "2.21%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 3741,
    "Actual Price(INR)/Quintal": 3738,
    "Percentage Difference": "-0.08%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 3709,
    "Actual Price(INR)/Quintal": 3894,
    "Percentage Difference": "4.75%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 3833,
    "Actual Price(INR)/Quintal": 3897,
    "Percentage Difference": "1.64%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 3834,
    "Actual Price(INR)/Quintal": 3813,
    "Percentage Difference": "-0.55%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 3755,
    "Actual Price(INR)/Quintal": 3745,
    "Percentage Difference": "-0.27%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 3680,
    "Actual Price(INR)/Quintal": 3704,
    "Percentage Difference": "0.65%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk1",
    "Predicted Price(INR)/Quintal": 3624,
    "Actual Price(INR)/Quintal": 3554,
    "Percentage Difference": "-1.97%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk2",
    "Predicted Price(INR)/Quintal": 3568,
    "Actual Price(INR)/Quintal": 3638,
    "Percentage Difference": "1.92%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk3",
    "Predicted Price(INR)/Quintal": 3571,
    "Actual Price(INR)/Quintal": 3795,
    "Percentage Difference": "5.90%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Nov Wk4",
    "Predicted Price(INR)/Quintal": 3693,
    "Actual Price(INR)/Quintal": 3645,
    "Percentage Difference": "-1.32%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 3684,
    "Actual Price(INR)/Quintal": 3587,
    "Percentage Difference": "-2.70%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 3528,
    "Actual Price(INR)/Quintal": 3443,
    "Percentage Difference": "-2.47%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 3434,
    "Actual Price(INR)/Quintal": 3424,
    "Percentage Difference": "-0.29%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 3410,
    "Actual Price(INR)/Quintal": 3317,
    "Percentage Difference": "-2.80%",
    "YEAR": 2017
  },
  {
    "Year : Month Week#": "Jan Wk1",
    "Predicted Price(INR)/Quintal": 4266,
    "Actual Price(INR)/Quintal": 4460,
    "Percentage Difference": "4.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk2",
    "Predicted Price(INR)/Quintal": 4275,
    "Actual Price(INR)/Quintal": 4416,
    "Percentage Difference": "3.19%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk3",
    "Predicted Price(INR)/Quintal": 4448,
    "Actual Price(INR)/Quintal": 4605,
    "Percentage Difference": "3.41%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jan Wk4",
    "Predicted Price(INR)/Quintal": 4672,
    "Actual Price(INR)/Quintal": 4730,
    "Percentage Difference": "1.23%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk1",
    "Predicted Price(INR)/Quintal": 4061,
    "Actual Price(INR)/Quintal": 3831,
    "Percentage Difference": "-6.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk2",
    "Predicted Price(INR)/Quintal": 4212,
    "Actual Price(INR)/Quintal": 4617,
    "Percentage Difference": "8.77%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk3",
    "Predicted Price(INR)/Quintal": 4618,
    "Actual Price(INR)/Quintal": 4644,
    "Percentage Difference": "0.26%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Feb Wk4",
    "Predicted Price(INR)/Quintal": 4623,
    "Actual Price(INR)/Quintal": 4808,
    "Percentage Difference": "3.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk1",
    "Predicted Price(INR)/Quintal": 4615,
    "Actual Price(INR)/Quintal": 4984,
    "Percentage Difference": "7.40%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk2",
    "Predicted Price(INR)/Quintal": 4815,
    "Actual Price(INR)/Quintal": 5147,
    "Percentage Difference": "6.45%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk3",
    "Predicted Price(INR)/Quintal": 5079,
    "Actual Price(INR)/Quintal": 5110,
    "Percentage Difference": "0.61%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Mar Wk4",
    "Predicted Price(INR)/Quintal": 5098,
    "Actual Price(INR)/Quintal": 5119,
    "Percentage Difference": "0.41%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk1",
    "Predicted Price(INR)/Quintal": 5114,
    "Actual Price(INR)/Quintal": 5160,
    "Percentage Difference": "0.89%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk2",
    "Predicted Price(INR)/Quintal": 5174,
    "Actual Price(INR)/Quintal": 4843,
    "Percentage Difference": "-6.84%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk3",
    "Predicted Price(INR)/Quintal": 4933,
    "Actual Price(INR)/Quintal": 4850,
    "Percentage Difference": "-1.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Apr Wk4",
    "Predicted Price(INR)/Quintal": 4950,
    "Actual Price(INR)/Quintal": 4808,
    "Percentage Difference": "-3.31%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk1",
    "Predicted Price(INR)/Quintal": 4772,
    "Actual Price(INR)/Quintal": 4818,
    "Percentage Difference": "0.56%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk2",
    "Predicted Price(INR)/Quintal": 4799,
    "Actual Price(INR)/Quintal": 4852,
    "Percentage Difference": "1.09%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk3",
    "Predicted Price(INR)/Quintal": 4802,
    "Actual Price(INR)/Quintal": 4802,
    "Percentage Difference": "0.00%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "May Wk4",
    "Predicted Price(INR)/Quintal": 4849,
    "Actual Price(INR)/Quintal": 4879,
    "Percentage Difference": "0.61%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk1",
    "Predicted Price(INR)/Quintal": 4829,
    "Actual Price(INR)/Quintal": 4923,
    "Percentage Difference": "1.91%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk2",
    "Predicted Price(INR)/Quintal": 4954,
    "Actual Price(INR)/Quintal": 4970,
    "Percentage Difference": "0.32%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk3",
    "Predicted Price(INR)/Quintal": 4934,
    "Actual Price(INR)/Quintal": 4833,
    "Percentage Difference": "-2.09%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jun Wk4",
    "Predicted Price(INR)/Quintal": 4858,
    "Actual Price(INR)/Quintal": 4948,
    "Percentage Difference": "1.82%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk1",
    "Predicted Price(INR)/Quintal": 4975,
    "Actual Price(INR)/Quintal": 4872,
    "Percentage Difference": "-2.11%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk2",
    "Predicted Price(INR)/Quintal": 4889,
    "Actual Price(INR)/Quintal": 4865,
    "Percentage Difference": "-0.49%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk3",
    "Predicted Price(INR)/Quintal": 4866,
    "Actual Price(INR)/Quintal": 4991,
    "Percentage Difference": "2.50%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Jul Wk4",
    "Predicted Price(INR)/Quintal": 4864,
    "Actual Price(INR)/Quintal": 4923,
    "Percentage Difference": "1.20%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk1",
    "Predicted Price(INR)/Quintal": 4920,
    "Actual Price(INR)/Quintal": 4903,
    "Percentage Difference": "-0.35%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk2",
    "Predicted Price(INR)/Quintal": 4916,
    "Actual Price(INR)/Quintal": 4872,
    "Percentage Difference": "-0.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk3",
    "Predicted Price(INR)/Quintal": 4537,
    "Actual Price(INR)/Quintal": 4376,
    "Percentage Difference": "-3.68%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Aug Wk4",
    "Predicted Price(INR)/Quintal": 4580,
    "Actual Price(INR)/Quintal": 4303,
    "Percentage Difference": "-3.69%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk1",
    "Predicted Price(INR)/Quintal": 4353,
    "Actual Price(INR)/Quintal": 4316,
    "Percentage Difference": "-0.86%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk2",
    "Predicted Price(INR)/Quintal": 4346,
    "Actual Price(INR)/Quintal": 4143,
    "Percentage Difference": "-4.90%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk3",
    "Predicted Price(INR)/Quintal": 3938,
    "Actual Price(INR)/Quintal": 4084,
    "Percentage Difference": "3.57%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Sep Wk4",
    "Predicted Price(INR)/Quintal": 4050,
    "Actual Price(INR)/Quintal": 3883,
    "Percentage Difference": "-4.30%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk1",
    "Predicted Price(INR)/Quintal": 3990,
    "Actual Price(INR)/Quintal": 4282,
    "Percentage Difference": "6.81%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk2",
    "Predicted Price(INR)/Quintal": 4223,
    "Actual Price(INR)/Quintal": 4448,
    "Percentage Difference": "5.06%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk3",
    "Predicted Price(INR)/Quintal": 4302,
    "Actual Price(INR)/Quintal": 4772,
    "Percentage Difference": "9.85%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Oct Wk4",
    "Predicted Price(INR)/Quintal": 4537,
    "Actual Price(INR)/Quintal": 4554,
    "Percentage Difference": "0.37%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk1",
    "Predicted Price(INR)/Quintal": 4498,
    "Actual Price(INR)/Quintal": 4378,
    "Percentage Difference": "-2.71%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk2",
    "Predicted Price(INR)/Quintal": 4328,
    "Actual Price(INR)/Quintal": 4215,
    "Percentage Difference": "-2.68%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk3",
    "Predicted Price(INR)/Quintal": 4127,
    "Actual Price(INR)/Quintal": 4274,
    "Percentage Difference": "3.44%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Now Wk4",
    "Predicted Price(INR)/Quintal": 4244,
    "Actual Price(INR)/Quintal": 4358,
    "Percentage Difference": "2.62%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk1",
    "Predicted Price(INR)/Quintal": 4297,
    "Actual Price(INR)/Quintal": 4302,
    "Percentage Difference": "0.12%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk2",
    "Predicted Price(INR)/Quintal": 4323,
    "Actual Price(INR)/Quintal": 4256,
    "Percentage Difference": "-1.57%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk3",
    "Predicted Price(INR)/Quintal": 4255,
    "Actual Price(INR)/Quintal": 4363,
    "Percentage Difference": "2.48%",
    "YEAR": 2016
  },
  {
    "Year : Month Week#": "Dec Wk4",
    "Predicted Price(INR)/Quintal": 4260,
    "Actual Price(INR)/Quintal": 4366,
    "Percentage Difference": "2.43%",
    "YEAR": 2016
  }
];
